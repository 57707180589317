import { CircularProgress, ThemeProvider } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import SpecialistApi from "../../api/specialistApi";
import UsersApi from "../../api/usersApi";
import { ContextProvider } from "../../contextProvider";
import { DiscountTypes, SpecialistData } from "../../types/TypeSpecialist";
import { TypeUpdatedUserInfo, TypeUserData } from "../../types/TypeUsers";
import {
  updateSpecialistIntroVideo,
  updateUserAvatar,
} from "../../utils/setupProfileInfo";
import { theme } from "../../utils/theme";
import Notify, { SuccesNotify } from "../../utils/toaster";
import ClientProfileContent from "./ClientProfileContent/ClientProfileContent";
import s from "./Profile.module.css";
import SpecialistProfileContent from "./SpecialistProfileContent/SpecialistProfileContent";

import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationsApi from "../../api/notifications";
import SessionsApi from "../../api/sessionApi";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import SelectSessionDate from "../../components/SelectSessionDate/SelectSessionDate";
import { ISO639LangReversed } from "../../constans/languagesList";
import strings from "../../localization";
import { setHeaderAvatar } from "../../redux-toolkit/slices/userSlice";
import { AppDispatch } from "../../redux-toolkit/store";
import { createChat } from "../../utils/general";
import { getTranslation } from "../../utils/getTranslation";

const Profile = () => {
  const token = localStorage.getItem("token");
  const isSpecialistStored = localStorage.getItem("isSpecialist");
  const isSpecialist =
    isSpecialistStored !== null ? JSON.parse(isSpecialistStored) : false;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const projectId = params.get("projectId") ?? undefined;
  const isFromTreeQuiz = params.get("isFromTreeQuiz") ?? undefined;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { userData, setUserData } = useContext(ContextProvider);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [isUpdateLoader, setIsUpdateLoader] = useState(false);
  const [calendarIsConnected, setCalendarIsConnected] = useState(false);
  const [profileUserData, setProfileUserData] = useState<
    (TypeUserData & { specialistId?: SpecialistData }) | undefined | null
  >();
  const [specialistData, setSpecialistData] = useState<
    | {
        specializationValue: string;
        specializationLable: string;
        specialistData: SpecialistData;
      }[]
    | undefined
  >();
  const [selectedSpecialistData, setSelectedSpecialistData] = useState<
    | {
        specializationValue: string;
        specializationLable: string;
        specialistData: SpecialistData;
      }
    | undefined
  >();
  const [specialistUpdateData, setSpecialistUpdateData] =
    useState<TypeUpdatedUserInfo>({
      _id: selectedSpecialistData
        ? selectedSpecialistData.specialistData._id
        : "",
    });
  const [video, setVideo] = useState<{ language: string; file: File }[] | null>(
    null
  );
  const [profileUserId, setProfileUserId] = useState<string | null>(null);
  const [userUpdateData, setUserUpdateData] = useState<TypeUpdatedUserInfo>({
    _id: userData?._id ? userData?._id : "",
  });
  const [bookingModalIsOpen, setBookingModalIsOpen] = useState(false);
  const [overlapTime, setOverlapTime] = useState<{
    [id: string]: { start: Date; end: Date }[];
  }>();

  const [isSpecialistProfile, setIsSpecialistProfile] = useState(false);
  const [allPriceData, setAllPriceData] = useState<{
    [id: string]: {
      price: number;
      discountPrice: number;
      language: string;
      discountType?: DiscountTypes;
    }[];
  }>();
  const [loaders, setLoaders] = useState({ getPriceLoader: true });
  const mainContentRef = useRef<HTMLDivElement | null>(null);

  const pathname = window.location.pathname;
  const pathParts = pathname.replace("/", "").split("-");
  const latinName = pathParts[0];
  const latinSurname = pathParts[1];
  const partialUserIdToGetInfo = pathParts[2];

  const updateData = async () => {
    setIsUpdateLoader(true);
    const specialistId = selectedSpecialistData?.specialistData._id;
    let responseSpecUpdateStatus = false;
    let responseUserUpdateStatus = false;
    const keysSpecialistDataUpdate = Object.keys(specialistUpdateData);

    if (video && token && specialistId) {
      const filesData = video.map((item) => ({
        language: item.language,
        video: item.file,
      }));
      await updateSpecialistIntroVideo(specialistId, filesData, token);
    }

    if (token && specialistId && keysSpecialistDataUpdate.length > 1) {
      const responseSpecUpdate = await SpecialistApi.update(
        specialistUpdateData,
        token
      );

      responseSpecUpdateStatus = responseSpecUpdate.status;
      /*       const updatedObject = userData;
      Object.keys(responseSpecUpdate.specialistData).forEach((key) => {
        //@ts-expect-error
        if (updatedObject.specialistIds)
          //@ts-expect-error
          updatedObject.specialistId[key] =
            //@ts-expect-error
            responseSpecUpdate.specialistData[key];
      });
      setUserData(updatedObject); */
    }

    const keysUserDataUpdate = Object.keys(userUpdateData);

    if (token && userData && userData._id && keysUserDataUpdate.length > 1) {
      const updatedObject = userData;
      Object.keys(userUpdateData).forEach((key) => {
        //@ts-expect-error
        updatedObject[key] = userUpdateData[key];
      });

      setUserData(updatedObject);
      const responseUserUpdate = await UsersApi.updateUserData(token, {
        ...userUpdateData,
        avatar: undefined,
      });

      responseUserUpdateStatus = responseUserUpdate.status;

      if (avatarFile) {
        const avatarUpdateRes = await updateUserAvatar(
          avatarFile,
          userData._id,
          token
        );
        if (avatarUpdateRes && avatarUpdateRes.status)
          dispatch(setHeaderAvatar(userUpdateData.avatar));
      }
    }

    if (responseUserUpdateStatus || responseSpecUpdateStatus) {
      setUserUpdateData({
        _id: userData?._id ? userData?._id : "",
      });
      SuccesNotify(strings.profileHasUpdate);
    }
    setIsEditProfile(false);
    setIsUpdateLoader(false);
  };
  useEffect(() => {
    if (JSON.stringify(avatarFile) === JSON.stringify(userData?.avatar)) return;
    if (avatarFile)
      setUserUpdateData((prev) => ({
        ...prev,
        avatar: URL.createObjectURL(avatarFile),
      }));
  }, [avatarFile]);

  useEffect(() => {
    if (
      token &&
      latinName &&
      latinSurname &&
      partialUserIdToGetInfo &&
      userData
    ) {
      const makeAsync = async () => {
        setIsLoader(true);

        const checkCalendarConnectResponse =
          await NotificationsApi.checkGoogleRefreshToken(token);

        setCalendarIsConnected(checkCalendarConnectResponse.status);

        const userDataPublicResponse =
          await UsersApi.getUserPublicInfoLatinName(
            partialUserIdToGetInfo,
            latinName,
            latinSurname,
            userData.selectedLanguage,
            token
          );

        const userDataResponse = await UsersApi.getUserProfileInfoById(
          token,
          userData._id
        );

        const availableUserData =
          partialUserIdToGetInfo === userData._id.slice(-7)
            ? userDataResponse.user
            : userDataPublicResponse.user;
        if (!userDataResponse.status && !userDataPublicResponse.status) {
          navigate("/home");
        }

        if (userDataResponse.status && availableUserData) {
          setProfileUserId(availableUserData._id);
          /*   setIsSpecialist(availableUserData.specialistId ? true : false); */
          setProfileUserData(availableUserData);
          const profileSpec =
            partialUserIdToGetInfo === userData._id.slice(-7)
              ? isSpecialist
              : availableUserData.specialistIds
              ? true
              : false;

          setIsSpecialistProfile(profileSpec);

          setSpecialistData(
            availableUserData.specialistIds &&
              availableUserData.specialistIds.map((item) => ({
                specializationValue: item.mainSpecializations[0].specialization,
                specializationLable: item.mainSpecializations[0].labels.find(
                  (el) => el.language === userData.selectedLanguage
                )!.text,
                specialistData: item,
              }))
          );
        }
        setIsLoader(false);
      };
      makeAsync();
    }
  }, [isUpdateLoader, latinName, latinSurname, partialUserIdToGetInfo]);

  useEffect(() => {
    if (!specialistData) return;
    setSelectedSpecialistData(specialistData[0]);
  }, [specialistData]);
  useEffect(() => {
    if (!selectedSpecialistData) return;
    setSpecialistUpdateData((prev) => ({
      ...prev,
      _id: selectedSpecialistData.specialistData._id,
    }));
  }, [selectedSpecialistData]);

  const bookingHandler = async (
    sessionBookingData: { date: Date | string; duration: number }[],
    specialistId: string,
    sessionLanguage: string,
    tags?: string[],
    subject?: string
  ) => {
    if (
      token &&
      userData &&
      userData._id &&
      profileUserData &&
      profileUserData._id &&
      selectedSpecialistData &&
      sessionBookingData.length
    ) {
      const response = await SessionsApi.bookSession(
        {
          datesAndTime: sessionBookingData,
          specialistUserId: profileUserData._id,
          userId: userData._id,
          specialistId: specialistId,
          projectId: projectId,
          tags: tags,
          subject: subject,
          sessionLanguage: sessionLanguage,
        },
        token
      );

      if (!response.status && !response.notEnoughBalance) {
        if (response.message) {
          Notify(response.message);
        }
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }

      if (response.status && profileUserData) {
        createChat(userData, profileUserData?._id);
        setIsLoader(false);
        setBookingModalIsOpen(false);
        SuccesNotify(strings.bookingSucces);
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
    }
    return { status: false, notEnoughBalance: false };
  };

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !profileUserId || !profileUserData || !specialistData)
        return;

      // Создание массива запросов для всех специалистов
      const requests = specialistData
        .map((specialist) =>
          profileUserData.preferredLanguages!.map((language) =>
            SpecialistApi.getSpecialistFreeTime(
              token,
              profileUserId,
              specialist.specialistData._id,
              ISO639LangReversed[language],
              14,
              projectId,
              isFromTreeQuiz === "true"
            )
          )
        )
        .flat();

      // Ожидание выполнения всех запросов
      setLoaders((prev) => ({ ...prev, getPriceLoader: true }));

      const responses = await Promise.all(requests);

      setLoaders((prev) => ({ ...prev, getPriceLoader: false }));

      if (responses && responses.length && responses.every((el) => el.status)) {
        const priceData = responses.reduce((acc, item) => {
          const dataToAppend = {
            price: specialistData.find(
              (el) => el.specialistData._id === item.specialistId
            )!.specialistData.price!,
            discountPrice: item.discountPrice,
            language: item.language,
            discountType: item.discountType,
          };

          acc[item.specialistId]
            ? acc[item.specialistId].push(dataToAppend)
            : (acc[item.specialistId] = [dataToAppend]);
          return acc;
        }, {} as { [id: string]: { price: number; discountPrice: number; language: string }[] });

        setAllPriceData(priceData);

        const allOverlaps = responses.reduce((acc, item) => {
          if (!item.overlapDays || !item.overlapDays[0]) return acc;
          const specialistId = item.overlapDays[0].specialist;

          const overlapTimes = item.overlapDays.flatMap((overlap) =>
            overlap.overlaps.flatMap((dayOverlap) => dayOverlap.overlapTime)
          );

          acc[specialistId] = overlapTimes.map((timeRange) => ({
            start: new Date(timeRange.start),
            end: new Date(timeRange.end),
          }));

          return acc;
        }, {} as { [id: string]: { start: Date; end: Date }[] });

        setOverlapTime(allOverlaps);
      }
    };

    makeAsync();
  }, [profileUserId, bookingModalIsOpen, specialistData]);

  useEffect(() => {
    if (!isEditProfile || !mainContentRef || !mainContentRef.current) return;
    mainContentRef.current.scrollTop = 0;
  }, [isEditProfile]);

  if (isLoader || isUpdateLoader || loaders.getPriceLoader)
    return (
      <div className="loader-container">
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
      </div>
    );

  return (
    <div
      className={s.container}
      /*   style={{ backgroundColor: isEditProfile ? "transparent" : "white" }} */
    >
      <div
        className={s.mainContentBlock}
        ref={mainContentRef}
        style={{ width: "100%" }}
      >
        {isSpecialistProfile &&
        selectedSpecialistData &&
        specialistData &&
        allPriceData ? (
          <SpecialistProfileContent
            isEdit={isEditProfile}
            setSpecialistUpdateData={setSpecialistUpdateData}
            specialistUpdateData={specialistUpdateData}
            profileUserData={profileUserData}
            specialistData={selectedSpecialistData?.specialistData}
            setUserUpdateData={setUserUpdateData}
            userUpdateData={userUpdateData}
            setVideo={setVideo}
            video={video}
            calendarIsConnected={calendarIsConnected}
            setIsEdit={setIsEditProfile}
            updateData={updateData}
            setBookingModalIsOpen={setBookingModalIsOpen}
            setAvatarFile={setAvatarFile}
            avatarFile={avatarFile}
            allSpecializationData={specialistData}
            setSelectedSpecialistData={setSelectedSpecialistData}
            projectId={projectId}
            priceData={allPriceData[selectedSpecialistData.specialistData._id]}
            allPriceData={allPriceData}
          />
        ) : (
          <ClientProfileContent
            isEdit={isEditProfile}
            setUserUpdateData={setUserUpdateData}
            userUpdateData={userUpdateData}
            profileUserData={profileUserData}
            calendarIsConnected={calendarIsConnected}
            setIsEdit={setIsEditProfile}
            updateData={updateData}
            setAvatarFile={setAvatarFile}
            avatarFile={avatarFile}
          />
        )}
      </div>

      <ModalWindow
        isOpen={bookingModalIsOpen}
        setIsOpen={setBookingModalIsOpen}
        width="fit-content"
        bgColor={overlapTime ? "White" : "transparent"}
        showCloseBtn={false}
      >
        {profileUserData &&
        profileUserData.specialistIds &&
        selectedSpecialistData &&
        allPriceData &&
        overlapTime ? (
          <SelectSessionDate
            allSpecializations={profileUserData.specialistIds.map((item) => ({
              specialistId: item._id,
              bookingTimeStep: item.bookingTimeStep,
              specialization: item.mainSpecializations[0].specialization,
              lable:
                item.mainSpecializations![0].labels.find(
                  (el) => el.language === userData?.selectedLanguage
                )?.text ?? item.mainSpecializations[0].specialization,
              tags: item.tags,
              introductionSession: item.introductionSession,
            }))}
            priceData={allPriceData}
            allSessionLangs={profileUserData.preferredLanguages!.map(
              (lang) => ({
                //@ts-expect-error
                lable: strings[lang],
                value: ISO639LangReversed[lang],
              })
            )}
            setModalOpen={setBookingModalIsOpen}
            bookingHandler={bookingHandler}
            overlapTime={overlapTime}
            userId={profileUserData ? profileUserData._id : ""}
            fullname={
              getTranslation(
                profileUserData?.name,
                userData?.selectedLanguage
              ) +
              " " +
              getTranslation(
                profileUserData?.surname,
                userData?.selectedLanguage
              )
            }
          />
        ) : (
          <ThemeProvider theme={theme}>
            <CircularProgress size={150} color="primary" />
          </ThemeProvider>
        )}
      </ModalWindow>
    </div>
  );
};

export default Profile;
