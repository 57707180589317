import { Tooltip } from "@mui/material";
import countries from "i18n-iso-countries";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import NotificationsApi from "../../../api/notifications";
import UsersApi from "../../../api/usersApi";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import ConfirmEmail from "../../../components/ConfirmEmail/ConfirmEmail";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { TypeUpdatedUserInfo, TypeUserData } from "../../../types/TypeUsers";
import { getTranslation } from "../../../utils/getTranslation";
import { formatDate } from "../../../utils/setupProfileInfo";
import PublishedPosts from "../SpecialistProfileContent/PublishedPosts/PublishedPosts";
import SubscribeBtn from "../SubscribeBtn/SubscribeBtn";
import s from "./ClientProfileContent.module.css";
import EditClientProfile from "./EditProfile/EditClientProfile";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

type ClientProfileContent = {
  isEdit: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  updateData: () => void;
  userUpdateData: TypeUpdatedUserInfo;
  setUserUpdateData: Dispatch<SetStateAction<TypeUpdatedUserInfo>>;
  profileUserData: TypeUserData | undefined | null;
  calendarIsConnected: boolean;
  setAvatarFile: Dispatch<SetStateAction<File | null>>;
  avatarFile: File | null;
};

const ClientProfileContent = ({
  isEdit,
  userUpdateData,
  setUserUpdateData,
  profileUserData,
  calendarIsConnected,
  updateData,
  setIsEdit,
  setAvatarFile,
  avatarFile,
}: ClientProfileContent) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { userData } = useContext(ContextProvider);
  const [confirmEmailModalIsOpen, setConfirmEmailModalIsOpen] = useState(false);
  const [confirmPhoneModalIsOpen, setConfirmPhoneModalIsOpen] = useState(false);
  const [isTwilioAvailable, setIsTwilioAvailable] = useState(false);
  /*   const [activeTab, setActiveTab] = useState<"general" | "posts">("general"); */
  const activeTab = "general";

  useEffect(() => {
    (async () => {
      const response = await UsersApi.isTwilioAvailable();
      setIsTwilioAvailable(response.isAvailable);
    })();
  }, []);

  const connectGoogleCalendar = async () => {
    if (!token) return;
    const response = await NotificationsApi.generateCode(token);
    if (response.status && response.url) {
      window.open(response.url);
    }
  };

  return (
    <div className={s.container}>
      {isEdit ? (
        <EditClientProfile
          profileUserData={profileUserData}
          setUserUpdateData={setUserUpdateData}
          userUpdateData={userUpdateData}
          updateData={updateData}
          calendarIsConnected={calendarIsConnected}
          avatarFile={avatarFile}
          setAvatarFile={setAvatarFile}
        />
      ) : (
        <>
          <div className={s.headBlock}>
            <div className={s.headNameBlock}>
              <div className={s.mobileAvatarBlock}>
                <CircleAvatar
                  userId={profileUserData?._id || ""}
                  height="110px"
                  width="110px"
                  fontSize="52px"
                  marginRight="20px"
                  isModalAvatar
                />
              </div>
              <div className={s.nameBlock}>
                <span className={s.nameTitle}>
                  {getTranslation(
                    profileUserData?.name,
                    userData?.selectedLanguage!
                  )}{" "}
                  {getTranslation(
                    profileUserData?.surname,
                    userData?.selectedLanguage!
                  )}
                </span>
                <span className={s.locationTitle}>
                  {profileUserData?.location}
                </span>
              </div>
            </div>
            <div className={s.bottomHeadBlock}>
              <div className={s.actionBlock}>
                {userData?._id === profileUserData?._id ||
                !profileUserData?._id ? (
                  <div className={s.myProfileActionBtn}>
                    <StandartButton
                      buttonTitle={strings.editProfileBtn}
                      action={() => setIsEdit(true)}
                    />
                  </div>
                ) : (
                  <SubscribeBtn userId={profileUserData?._id!} />
                )}
              </div>
            </div>
          </div>

          {activeTab === "general" ? (
            <div className={s.mainContentBlock}>
              <div className={s.mainInfoBlock}>
                <div className={s.infoTitleBlock}>
                  <span>{strings.profilePersonInfo}</span>
                </div>
                <div className={s.personInfoBlock}>
                  <div className={s.personInfoLineBlock}>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>
                        {strings.firstNameProfileLable}
                      </span>

                      <span className={s.infoValue}>
                        {getTranslation(
                          profileUserData?.name,
                          userData?.selectedLanguage!
                        )}
                      </span>
                    </div>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>
                        {strings.lastNameProfileLable}
                      </span>

                      <span className={s.infoValue}>
                        {getTranslation(
                          profileUserData?.surname,
                          userData?.selectedLanguage!
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={s.personInfoBlock}>
                  <div className={s.personInfoLineBlock}>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>
                        {strings.dateBirthProfileLable}
                      </span>

                      <span className={s.infoValue}>
                        {formatDate(
                          profileUserData?.dateOfBirth
                            ? new Date(profileUserData?.dateOfBirth)
                            : new Date()
                        )}
                      </span>
                    </div>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>
                        {strings.timezoneProfileLable}
                      </span>

                      <span className={s.infoValue}>
                        {profileUserData?.timezone}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={s.personInfoBlock}>
                  <div className={s.personInfoLineBlock}>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>
                        {strings.fromProfileLable}
                      </span>

                      <span className={s.infoValue}>
                        {profileUserData?.location}
                      </span>
                    </div>
                    <div className={s.personInfoElement}>
                      <span className={s.infoLable}>
                        {strings.langProfileLable}
                      </span>

                      <div className={s.langBlock}>
                        {profileUserData &&
                          profileUserData.preferredLanguages &&
                          profileUserData?.preferredLanguages.map(
                            (item, index) => (
                              <span className={s.infoValue} key={index}>
                                {item}
                              </span>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={s.rightBlock}>
                {profileUserData?._id === userData?._id && (
                  <div className={s.contactsBlock}>
                    <h4 className={s.blockLable}>{strings.myContacts}</h4>
                    <div className={s.contactElement}>
                      <span className={s.infoLable}>
                        {strings.phoneNumberLable}
                      </span>
                      <span className={s.infoValue}>
                        {userData?.phoneNumber}
                      </span>
                      {!userData?.isPhoneNumberConfirmed &&
                        isTwilioAvailable && (
                          <div className={s.unverifiedEmailBlock}>
                            <span className={s.warningText}>
                              {strings.phoneNotVerified}
                            </span>
                            <button
                              className={s.verifyButton}
                              onClick={() => setConfirmPhoneModalIsOpen(true)}
                            >
                              {strings.verifyPhoneButton}
                            </button>
                          </div>
                        )}
                    </div>
                    <div className={s.contactElement}>
                      <span className={s.infoLable}>
                        {strings.emailAuthLable}
                      </span>
                      <span className={s.infoValue}>{userData?.email}</span>
                      {!userData?.isEmailConfirmed && (
                        <div className={s.unverifiedEmailBlock}>
                          <span className={s.warningText}>
                            {strings.emailNotVerified}
                          </span>
                          <button
                            className={s.verifyButton}
                            onClick={() => setConfirmEmailModalIsOpen(true)}
                          >
                            {strings.verifyEmailButton}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className={s.integrationsBlock}>
                  <h4 className={s.blockLable}>{strings.intagrationProfile}</h4>

                  <div className={s.integrationsElement}>
                    <p className={s.infoLable}>
                      {strings.connectGoogleProfile}
                    </p>
                    <Tooltip
                      title={
                        calendarIsConnected ? strings.connectGoogleTooltip : ""
                      }
                      placement="right"
                      arrow
                    >
                      <div
                        style={{
                          width: "100%",
                          marginTop: "10px",
                        }}
                      >
                        <StandartButton
                          buttonTitle={strings.connectProfileBtn}
                          action={connectGoogleCalendar}
                          width="100%"
                          disabled={calendarIsConnected}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
                {profileUserData?._id === userData?._id &&
                  (!userData?.specialistIds ||
                    !userData.specialistIds.length) && (
                    <div className={s.integrationsBlock}>
                      <h4 className={s.blockLable}>{strings.becomeSpec}</h4>
                      <div className={s.integrationsElement}>
                        <p className={s.integartionsElementTitle}>
                          {strings.becomeSpecDescription}
                        </p>

                        <StandartButton
                          buttonTitle={strings.addSpecialtyBtn}
                          action={() => navigate("/new-speciality")}
                          width="100%"
                          disabled={calendarIsConnected}
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
          ) : (
            <PublishedPosts profileUserData={profileUserData} />
          )}
        </>
      )}
      <ModalWindow
        isOpen={confirmEmailModalIsOpen}
        setIsOpen={setConfirmEmailModalIsOpen}
        width={"fit-content"}
      >
        <ConfirmEmail
          setModal={setConfirmEmailModalIsOpen}
          codeLength={5}
          verificationType={"email"}
        />
      </ModalWindow>
      <ModalWindow
        isOpen={confirmPhoneModalIsOpen}
        setIsOpen={setConfirmPhoneModalIsOpen}
        width={"fit-content"}
      >
        <ConfirmEmail
          setModal={setConfirmPhoneModalIsOpen}
          codeLength={5}
          verificationType={"phoneNumber"}
        />
      </ModalWindow>
    </div>
  );
};

export default ClientProfileContent;
