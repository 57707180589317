import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import UsersApi from "../../../api/usersApi";
import GroupBg2 from "../../../assets/Auth/Group-1.png";
import GroupBg3 from "../../../assets/Auth/Group-2.png";
import GroupBg1 from "../../../assets/Auth/Group.png";
import LockIng from "../../../assets/Auth/lock.png";
import Input from "../../../components/Input/Input";
import StandartButton from "../../../components/StandartButton/StandartButton";
import TimerComponent from "../../../components/TimerComponent/TimerComponent";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { storeData } from "../../../utils/general";
import { SuccesNotify } from "../../../utils/toaster";
import s from "./ConfirmRegistration.module.css";

const ConfirmRegistration = () => {
  const selectlang = localStorage.getItem("lang");
  const skipTwilioConfirm = localStorage.getItem("skipTwilioConfirm");
  const userId = localStorage.getItem("userId");
  const {
    setIsAuth,
    setUserData,
    setSelectLang,
    selectLang,
    setIsSetupProfileInfo,
  } = useContext(ContextProvider);
  const [confirmeCode, setConfirmeCode] = useState("");
  const [error, setError] = useState("");
  const [lastResendDate, setLastResendDate] = useState<Date | undefined>();
  const [timeOff, setTimeOff] = useState(false);
  const navigate = useNavigate();

  const timeLimit: number = 5 * 60 * 1000;

  const handleConfirm = async () => {
    if (userId && confirmeCode) {
      const response = await UsersApi.confirmRegister({
        id: userId,
        code: Number(confirmeCode),
        selectedLanguage: selectLang ?? undefined,
      });

      if (!response.status && response.message) {
        setError(response.message);
      }
      if (
        response.status &&
        response.token &&
        response.userData &&
        response.refreshToken
      ) {
        const updateResponse = await UsersApi.updateUserData(response.token, {
          _id: response.userData._id,
          selectedLanguage: selectlang ?? "en",
        });
        if (updateResponse.status && updateResponse.userData) {
          setUserData(updateResponse.userData);
          storeData(response.token, "token");
          storeData(response.refreshToken, "refreshToken");
          setIsAuth(true);
          localStorage.removeItem("username");
          setIsSetupProfileInfo(updateResponse.userData.registerFinished);
          navigate(
            updateResponse.userData.registerFinished
              ? "/home"
              : "/setup-professional-info"
          );
        }
      }
    }
  };

  const getUserNameAndResendDate = async () => {
    if (!userId) return;
    const response = await UsersApi.getUserPublicInfoById(userId, "en");

    if (response.status && response.user) {
      if (response.user.resendConfCodeDate) {
        setLastResendDate(
          new Date(response.user.resendConfCodeDate.replace("Z", ""))
        );
        setTimeOff(false);
        return;
      }
      if (response.user.resendEmailConfCodeDate && skipTwilioConfirm) {
        setLastResendDate(
          new Date(response.user.resendEmailConfCodeDate.replace("Z", ""))
        );
        setTimeOff(false);
        return;
      }
    }
  };
  useEffect(() => {
    getUserNameAndResendDate();
  }, [userId]);

  const handleResend = async () => {
    if (!userId) return;
    const response = await UsersApi.resendConfCode({ userId: userId });
    if (!response.status && response.message) {
      setError(response.message);
    }
    if (response.status) {
      setError("");
      SuccesNotify(strings.codeSent);
      setUserData(response.userData);
      getUserNameAndResendDate();
    }
  };
  const changeLangHandler = async (value: string) => {
    strings.setLanguage(value);
    setSelectLang(value);
  };

  return (
    <div className={s.container} data-testid="confirm-reg">
      <div className={s.langSelectBlock}>
        <button
          onClick={() => changeLangHandler("en")}
          className={selectLang === "en" ? s.selectLang : s.unselectLang}
        >
          EN
        </button>
        <button
          onClick={() => changeLangHandler("uk")}
          className={selectLang === "uk" ? s.selectLang : s.unselectLang}
        >
          УКР
        </button>
      </div>
      <div className={s.mainContentBlock}>
        <img src={LockIng} alt="" className={s.logo} />
        <h1 className={s.headTitle}>
          {skipTwilioConfirm ? strings.emailConfirm : strings.phoneConfirm}
        </h1>
        <p className={s.codeDescription}>
          {skipTwilioConfirm
            ? strings.emailConfirmDescription
            : strings.phoneConfirmDescription}
        </p>
        <div className={s.actionBlock}>
          <Input
            inputValue={confirmeCode}
            onChangeInput={(value) => setConfirmeCode(value)}
            isVisible
            required
            lable={strings.codeLable}
            placeholder="00000"
          />

          {lastResendDate && !timeOff && (
            <div className={s.resendBlock}>
              <span>{strings.youCanResend}</span>
              <TimerComponent
                date={lastResendDate}
                timeLimit={timeLimit}
                timeOffFunc={(value) => setTimeOff(value)}
                renderTimerFunc={(value) => {
                  return (
                    <span className={s.remaingTime}>
                      {value.minutes.toString().padStart(2, "0")} :{" "}
                      {value.seconds.toString().padStart(2, "0")}
                    </span>
                  );
                }}
              />
            </div>
          )}
          {lastResendDate && timeOff && (
            <div className={s.resendBlock}>
              <span>{strings.dintReciveCode}</span>
              <span role="button" onClick={handleResend}>
                {strings.resendBtn}
              </span>
            </div>
          )}

          <span className={s.errorMessage}>{error}</span>
          <div className={s.buttonBlock}>
            <StandartButton
              buttonTitle={strings.confirmBtn}
              action={handleConfirm}
              fontSize="14px"
              width="100%"
              disabled={confirmeCode.length === 5 ? false : true}
            />
          </div>
        </div>
      </div>
      <div className={s.footerBlock}></div>

      <img src={GroupBg1} alt="" className={s.group1} />
      <img src={GroupBg2} alt="" className={s.group2} />
      <img src={GroupBg3} alt="" className={s.group3} />
    </div>
  );
};

export default ConfirmRegistration;
