import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnUnderline,
  BtnUndo,
  Editor,
  EditorProvider,
  Separator,
  Toolbar,
} from "react-simple-wysiwyg";
import VirtualList from "react-virtual-drag-list";
import ProjectApi from "../../../api/projectApi";
import SessionsApi from "../../../api/sessionApi";
import SpecialistApi from "../../../api/specialistApi";
import { ReactComponent as Clock } from "../../../assets/SpecTools/clock.svg";
import { ReactComponent as Delete } from "../../../assets/SpecTools/delete.svg";
import { ReactComponent as MenuIcon } from "../../../assets/SpecTools/menu.svg";
import { ReactComponent as Refresh } from "../../../assets/SpecTools/refresh.svg";
import { ReactComponent as Stickynote } from "../../../assets/SpecTools/stickynote.svg";
import { ReactComponent as Upload } from "../../../assets/SpecTools/upload.svg";
import Input from "../../../components/Input/Input";
import Loader from "../../../components/Loader/Loader";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import MultipleSelect from "../../../components/MultipleSelect/MultipleSelect";
import StandartButton from "../../../components/StandartButton/StandartButton";
import {
  imageExtensions,
  videoExtensions,
} from "../../../constans/filesExtensions";
import { ISO639LangReversed } from "../../../constans/languagesList";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { SpecialistTagsDirections } from "../../../types/TypeSpecialist";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import PackageInfo from "../../PackageInfo/PackageInfo";
import PackageSessionCard from "./PackageSessionCard/PackageSessionCard";
import s from "./PackagesTool.module.css";

export type NewPackageType = {
  title: { text: string; language: string }[];
  description: { text: string; language: string }[];
  packageCost: number;
  tags: string[];
  isActive: boolean;
  specializations: string[];
  directions: string[];
  sessions: {
    id: number;
    title: { text: string; language: string }[];
    description?: { text: string; language: string }[];
    datesAndTime: {
      duration: number;
    }[];
    nextSessionGap: { min: number; max: number };
    specialization: SpecialistTagsDirections | string;
    order: number;
  }[];
  project?: string | boolean;
};

const PackagesTool = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const navigate = useNavigate();
  const windowWidth = window.innerWidth;
  const packageId =
    location.state && location.state.packageId
      ? location.state.packageId
      : undefined;
  const { userData } = useContext(ContextProvider);
  const [specializationsData, setSpecializationsData] = useState<
    | {
        value: string;
        lable: string;
        specialization: SpecialistTagsDirections | undefined;
        mainSpecializationRef: string;
      }[]
    | null
  >(null);
  const emtyTextFieldArray = userData!.preferredLanguages!.map((item) => ({
    language: ISO639LangReversed[item],
    text: "",
  }));

  const [newPackageData, setNewPackageData] = useState<NewPackageType>({
    title: emtyTextFieldArray,
    description: emtyTextFieldArray,
    packageCost: 0,
    isActive: false,
    tags: [],
    specializations: [],
    directions: [],
    sessions: [],
  });

  const [newPackageFiles, setNewPackageFiles] = useState<{
    video: File | null;
    image: File | null;
  }>({ video: null, image: null });
  const [actionLoaders, setActionLoaders] = useState({ generalLoader: false });
  const [currentPackageFile, setCurrentPackageFile] = useState<{
    video: { _id: string; name: string; link: string } | null;
    image: { _id: string; link: string } | null;
  }>({ video: null, image: null });
  const [previewPackageModal, setPreviewPackageModal] = useState(false);
  const [tagsDirection, setTagsDirection] = useState<
    SpecialistTagsDirections | undefined
  >();
  const [projectsData, setProjectsData] = useState<
    | {
        value: string;
        lable: string;
      }[]
    | undefined
  >();
  const [tags, setTags] = useState<
    { lable: string; value: string; parentTag?: string }[] | undefined
  >();
  const [availableLangs, setAvailableLangs] = useState({
    langsData: userData!.preferredLanguages!,
    selectLang: ISO639LangReversed[userData!.preferredLanguages![0]],
  });

  const packageStatusData = [
    { value: "true", lable: strings.publishedStatus },
    { value: "false", lable: strings.draftStatus },
  ];

  const directions: { [key: string]: SpecialistTagsDirections } = {
    ["Coach"]: SpecialistTagsDirections.COACHING,
    ["Psychologist"]: SpecialistTagsDirections.PSYTHERAPHY,
    ["Mentor"]: SpecialistTagsDirections.MENTORING,
  };

  useEffect(() => {
    if (
      !newPackageData.specializations ||
      !newPackageData.specializations.length ||
      !specializationsData ||
      !userData ||
      !userData.specialistIds
    )
      return;

    const currentSelectSpecializationRef = specializationsData.find(
      (el) => el.value === newPackageData.specializations[0]
    )?.mainSpecializationRef;

    if (!currentSelectSpecializationRef) return;
    const specialistId = userData.specialistIds.filter(
      (el) => el.mainSpecializations[0]._id === currentSelectSpecializationRef
    )[0]._id;

    if (!specialistId || !token) return;
    (async () => {
      const response = await ProjectApi.getMyProjectsWithoutPackage(
        token,
        specialistId
      );

      if (response.status && response.projects && response.projects.length) {
        setProjectsData(
          response.projects.map((item) => ({
            lable: item.title,
            value: item._id,
          }))
        );
      }
    })();
  }, [newPackageData.specializations]);

  useEffect(() => {
    if (
      !userData ||
      !userData.specialistIds ||
      userData.specialistIds.length > 1 ||
      !specializationsData
    )
      return;
    setTagsDirection(
      directions[
        userData!
          .specialistIds!.map((item) => ({
            _id: item.mainSpecializations[0]._id,
            specialization: item.mainSpecializations[0].specialization,
          }))
          .find(
            (el) =>
              el._id === userData.specialistIds![0].mainSpecializations[0]._id
          )!.specialization
      ]
    );

    setNewPackageData((prev) => ({
      ...prev,
      specializations: [specializationsData[0].value],
    }));
  }, [userData, specializationsData]);

  useEffect(() => {
    if (
      !specializationsData ||
      !packageId ||
      !newPackageData.specializations.length
    )
      return;
    setTagsDirection(
      specializationsData.find(
        (el) => el.value === newPackageData.specializations[0]
      )!.specialization
    );
  }, [packageId, specializationsData, newPackageData.specializations]);

  useEffect(() => {
    const makeAsync = async () => {
      if (!packageId || !token) return;
      const response = await SessionsApi.getSpecialistSessionPackageById(
        token,
        packageId
      );

      if (
        response.status &&
        response.package &&
        (response.package.introductionVideo || response.package.previewImage)
      ) {
        const filesResponse = await SessionsApi.getPackageFiles(
          token,
          response.package._id
        );
        if (
          filesResponse.status &&
          filesResponse.packageVideos &&
          filesResponse.packageVideos[0]
        ) {
          setCurrentPackageFile((prev) => ({
            ...prev,
            video: {
              _id: filesResponse.packageVideos![0]._id,
              name: filesResponse.packageVideos![0].filename,
              link: filesResponse.packageVideos![0].link,
            },
          }));
        }
        if (
          filesResponse.status &&
          filesResponse.packageImages &&
          filesResponse.packageImages[0]
        ) {
          setCurrentPackageFile((prev) => ({
            ...prev,
            image: {
              _id: filesResponse.packageImages![0]._id,
              link: filesResponse.packageImages![0].link,
            },
          }));
        }
      }

      if (response.status && response.package) {
        if (response.package.project) {
          setProjectsData((prev) => {
            if (prev) {
              return [
                ...prev,
                {
                  value: response.package!.project!._id,
                  lable: response.package!.project!.title,
                },
              ];
            } else {
              return [
                {
                  value: response.package!.project!._id,
                  lable: response.package!.project!.title,
                },
              ];
            }
          });
        }
        function filterByLanguage(
          array1: { text: string; language: string }[],
          array2: { text: string; language: string }[]
        ): { text: string; language: string }[] {
          // Получаем список всех языков из второго массива
          const languagesToRemove = new Set(
            array2.map((item) => item.language)
          );

          // Фильтруем первый массив, исключая элементы с совпадающим language
          return array1.filter((item) => !languagesToRemove.has(item.language));
        }

        const newLang = filterByLanguage(
          emtyTextFieldArray,
          response.package.title
        );

        const responsePackageData = {
          title: [...response.package.title, ...newLang],
          description: response.package.description
            ? [...response.package.description, ...newLang]
            : emtyTextFieldArray,
          packageCost: (response.package.packageCost / 100).toFixed(0),
          isActive: response.package.isActive,
          tags: response.package.tags ?? [],
          specializations: response.package.specializations
            ? response.package.specializations.map((item) => item._id)
            : [],
          sessions: response.package.sessions.map((item) => ({
            ...item,
            title: [...item.title, ...newLang],
            description: item.description
              ? [...item.description, ...newLang]
              : emtyTextFieldArray,
          })),
          directions: response.package.directions.map((item) => item._id),
          project: response.package.project?._id,
        };

        //@ts-expect-error
        setNewPackageData(responsePackageData);
      }
    };
    makeAsync();
  }, [packageId]);

  const gatTags = async () => {
    if (!token || !userData || !tagsDirection) return;
    const payload = {
      level: 1,
      direction: tagsDirection,
    };
    const response = await SpecialistApi.getSpecialistTags(token, payload);
    if (response.status && response.tags) {
      const tagsData = response.tags.map((item) => ({
        lable: item.labels.find(
          (el) => el.language === userData.selectedLanguage
        )!.text,
        value: item._id,
        parentTag: item.parentTag,
      }));
      setTags(tagsData);
    }
  };
  useEffect(() => {
    if (!tagsDirection) return;

    gatTags();
  }, [tagsDirection]);

  useEffect(() => {
    (async () => {
      if (!token || !userData || !userData.specialistIds) return;
      const response = await SpecialistApi.getAllSpecialzations(token);
      if (response.status && response.specializations) {
        const userDataSpecialization = userData.specialistIds.map(
          (item) => item.mainSpecializations[0]
        );
        const availabelSpecializations = response.specializations.filter(
          (responseSpecialization) =>
            userDataSpecialization.some(
              (userDataSpecialization) =>
                responseSpecialization.specialization ===
                directions[userDataSpecialization.specialization]
            )
        );

        setSpecializationsData(
          availabelSpecializations.map((item) => ({
            value: item._id,
            lable: item.labels.find(
              (el) => el.language === userData.selectedLanguage
            )?.text!,
            specialization: item.specialization as SpecialistTagsDirections,
            mainSpecializationRef: item.mainSpecializationRef,
          }))
        );
      }
    })();
  }, []);

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    fileType: "video" | "image"
  ) => {
    const file = event.target.files && event.target.files[0];

    if (!file) return;

    // Проверка размера: 20MB для изображения и 50MB для видео
    const imageSizeLimit = 20 * 1024 * 1024; // 20 MB
    const videoSizeLimit = 50 * 1024 * 1024; // 50 MB

    if (fileType === "image" && file.size >= imageSizeLimit) {
      const error = strings.formatString(strings.fileSizeMoreThan, {
        size: "20",
      });
      Notify(error as string);
      return;
    }

    if (fileType === "video" && file.size >= videoSizeLimit) {
      const error = strings.formatString(strings.fileSizeMoreThan, {
        size: "50",
      });
      Notify(error as string);
      return;
    }

    // Если все ок, обновляем стейт
    if (fileType === "image") {
      setNewPackageFiles((prev) => ({ ...prev, image: file }));
    } else if (fileType === "video") {
      setNewPackageFiles((prev) => ({ ...prev, video: file }));
    }
  };

  const getAllSessionDuration = () => {
    if (!newPackageData.sessions.length) return 0;
    const durationArr = newPackageData.sessions.map(
      (item) => item.datesAndTime[0].duration
    );

    let result = durationArr.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    result = Number((result / 60).toFixed(1));
    return result;
  };

  const onDrop = (event: {
    event: { oldIndex: number; newIndex: number };
    list: any[];
  }) => {
    const items = event.list;

    setNewPackageData((prev) => ({ ...prev, sessions: items }));
  };
  const isValidMultiLangFields = (
    arr: { language: string; text: string }[][]
  ) => {
    return arr.every((array) =>
      array.every((item) => item.text !== undefined && item.text.length > 0)
    );
  };
  const validatePackageFields = () => {
    if (
      !newPackageData.packageCost ||
      !newPackageData.sessions.length ||
      !newPackageData.specializations.length ||
      !newPackageData.directions.length ||
      !isValidMultiLangFields([
        newPackageData.description,
        newPackageData.title,
        ...newPackageData.sessions.map((item) => item.title),
        /*    ...newPackageData.sessions.map(
          (item) => item.description ?? item.title
        ), */
      ])
    )
      return false;
    return true;
  };

  const createNewPackage = async () => {
    if (!validatePackageFields()) {
      return Notify(strings.packageRequiredFieldsNotify);
    }
    if (!token || !specializationsData || !userData || !userData.specialistIds)
      return;
    const newPackage = {
      ...newPackageData,
    };
    const currentSelectSpecializationRef = specializationsData.find(
      (el) => el.value === newPackageData.specializations[0]
    )?.mainSpecializationRef;
    if (!currentSelectSpecializationRef) return;
    const specialistId = userData.specialistIds.filter(
      (el) => el.mainSpecializations[0]._id === currentSelectSpecializationRef
    )[0]._id;

    if (!specialistId) return;
    const payload = {
      newPackage: newPackage,
      specialistId: specialistId,
      userId: userData!._id,
    };

    setActionLoaders((prev) => ({ ...prev, generalLoader: true }));
    const response = await SessionsApi.createSessionPackage(token, payload);

    if (!response.status && response.message) {
      Notify(response.message);
    }
    if (response.status && response.package) {
      if (!newPackageFiles.video && !newPackageFiles.image) {
        SuccesNotify(strings.createPackageSucces);
        setNewPackageData({
          title: emtyTextFieldArray,
          description: emtyTextFieldArray,
          packageCost: 0,
          isActive: true,
          tags: [],
          specializations: [],
          sessions: [],
          directions: [],
        });
        setNewPackageFiles({ video: null, image: null });
        setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
        navigate(
          `/${userData.latinName}-${userData.latinSurname}-${userData._id.slice(
            -7
          )}`,
          {
            state: {
              activeProfileTab: "packages",
            },
          }
        );
        return;
      }
      if (newPackageFiles.video && newPackageFiles.image) {
        const videoUploadStatus = await setPackageFiles(
          response.package._id,
          "video"
        );
        if (videoUploadStatus) {
          const imageUploadStatus = await setPackageFiles(
            response.package._id,
            "image"
          );
          if (imageUploadStatus) {
            SuccesNotify(strings.createPackageSucces);
            setNewPackageData({
              title: emtyTextFieldArray,
              description: emtyTextFieldArray,
              packageCost: 0,
              isActive: true,
              tags: [],
              specializations: [],
              sessions: [],
              directions: [],
            });
            setNewPackageFiles({ video: null, image: null });
            setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
            navigate(
              `/${userData.latinName}-${
                userData.latinSurname
              }-${userData._id.slice(-7)}`,
              {
                state: {
                  activeProfileTab: "packages",
                },
              }
            );
            return;
          }
        }
      }
      if (newPackageFiles.video && !newPackageFiles.image) {
        const videoUploadStatus = await setPackageFiles(
          response.package._id,
          "video"
        );
        if (videoUploadStatus) {
          SuccesNotify(strings.updatedPackageNotify);
          setNewPackageData({
            title: emtyTextFieldArray,
            description: emtyTextFieldArray,
            packageCost: 0,
            isActive: true,
            tags: [],
            specializations: [],
            sessions: [],
            directions: [],
          });
          setNewPackageFiles({ video: null, image: null });
          setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
          navigate(
            `/${userData.latinName}-${
              userData.latinSurname
            }-${userData._id.slice(-7)}`,
            {
              state: {
                activeProfileTab: "packages",
              },
            }
          );
          return;
        }
      }
      if (!newPackageFiles.video && newPackageFiles.image) {
        const imageUploadStatus = await setPackageFiles(
          response.package._id,
          "image"
        );
        if (imageUploadStatus) {
          SuccesNotify(strings.updatedPackageNotify);
          setNewPackageData({
            title: emtyTextFieldArray,
            description: emtyTextFieldArray,
            packageCost: 0,
            isActive: true,
            tags: [],
            specializations: [],
            sessions: [],
            directions: [],
          });
          setNewPackageFiles({ video: null, image: null });
          setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
          navigate(
            `/${userData.latinName}-${
              userData.latinSurname
            }-${userData._id.slice(-7)}`,
            {
              state: {
                activeProfileTab: "packages",
              },
            }
          );
          return;
        }
      }
    }
    setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
  };

  /*   const handleTagsInputChange = (value: string) => {
    if (value.length >= 32) return;
    const filteredValue = value.replace(/[^a-zA-Z0-9]/g, "");
    setTagsInput(filteredValue);
  };

  const addTagHandle = (value: string) => {
    setNewPackageData((prev) => ({ ...prev, tags: [...prev.tags, value] }));
    setTagsInput("");
  }; */

  const updatePackage = async () => {
    if (!token || !userData) return;
    const updateData = {
      ...newPackageData,
      _id: packageId,
    };

    if (!validatePackageFields()) {
      return Notify(strings.packageRequiredFieldsNotify);
    }
    setActionLoaders((prev) => ({ ...prev, generalLoader: true }));
    const response = await SessionsApi.updatePackage(token, updateData);

    if (!response.status && response.message) {
      Notify(response.message);
    }
    if (response.status && response.package) {
      if (!newPackageFiles.video && !newPackageFiles.image) {
        SuccesNotify(strings.updatedPackageNotify);
        setNewPackageData({
          title: emtyTextFieldArray,
          description: emtyTextFieldArray,
          packageCost: 0,
          isActive: true,
          tags: [],
          specializations: [],
          sessions: [],
          directions: [],
        });
        setNewPackageFiles({ video: null, image: null });
        setActionLoaders((prev) => ({ ...prev, generalLoader: false }));

        navigate(
          `/${userData.latinName}-${userData.latinSurname}-${userData._id.slice(
            -7
          )}`,
          {
            state: {
              activeProfileTab: "packages",
            },
          }
        );
        return;
      }
      if (newPackageFiles.video && newPackageFiles.image) {
        const videoUploadStatus = await setPackageFiles(
          response.package._id,
          "video"
        );
        if (videoUploadStatus) {
          const imageUploadStatus = await setPackageFiles(
            response.package._id,
            "image"
          );
          if (imageUploadStatus) {
            SuccesNotify(strings.updatedPackageNotify);
            setNewPackageData({
              title: emtyTextFieldArray,
              description: emtyTextFieldArray,
              packageCost: 0,
              isActive: true,
              tags: [],
              specializations: [],
              sessions: [],
              directions: [],
            });
            setNewPackageFiles({ video: null, image: null });
            setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
            navigate(
              `/${userData.latinName}-${
                userData.latinSurname
              }-${userData._id.slice(-7)}`,
              {
                state: {
                  activeProfileTab: "packages",
                },
              }
            );
            return;
          }
        }
      }
      if (newPackageFiles.video && !newPackageFiles.image) {
        const videoUploadStatus = await setPackageFiles(
          response.package._id,
          "video"
        );
        if (videoUploadStatus) {
          SuccesNotify(strings.updatedPackageNotify);
          setNewPackageData({
            title: emtyTextFieldArray,
            description: emtyTextFieldArray,
            packageCost: 0,
            isActive: true,
            tags: [],
            specializations: [],
            sessions: [],
            directions: [],
          });
          setNewPackageFiles({ video: null, image: null });
          setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
          navigate(
            `/${userData.latinName}-${
              userData.latinSurname
            }-${userData._id.slice(-7)}`,
            {
              state: {
                activeProfileTab: "packages",
              },
            }
          );
          return;
        }
      }
      if (!newPackageFiles.video && newPackageFiles.image) {
        const imageUploadStatus = await setPackageFiles(
          response.package._id,
          "image"
        );
        if (imageUploadStatus) {
          SuccesNotify(strings.updatedPackageNotify);
          setNewPackageData({
            title: emtyTextFieldArray,
            description: emtyTextFieldArray,
            packageCost: 0,
            isActive: true,
            tags: [],
            specializations: [],
            sessions: [],
            directions: [],
          });
          setNewPackageFiles({ video: null, image: null });
          setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
          navigate(
            `/${userData.latinName}-${
              userData.latinSurname
            }-${userData._id.slice(-7)}`,
            {
              state: {
                activeProfileTab: "packages",
              },
            }
          );
          return;
        }
      }
    }
  };

  const setPackageFiles = async (
    packageId: string,
    fileType: "image" | "video",
    returnPackage?: true
  ) => {
    if (!token) return;
    const formData = new FormData();
    if (fileType === "image" && newPackageFiles.image) {
      formData.append("file", newPackageFiles.image);
    }
    if (fileType === "video" && newPackageFiles.video) {
      formData.append("file", newPackageFiles.video);
    }
    if (returnPackage) {
      formData.append("returnPackage", returnPackage.toString());
    }
    formData.append("fileType", fileType);

    const response = await SessionsApi.setSessionPackagePreviewFile(
      token,
      packageId,
      formData
    );

    return response.status;
  };

  const deleteFile = async (
    fileId: string,
    fileType: "packageImage" | "packageVideo" | "packageSessionImage"
  ) => {
    if (!token || !packageId) return;
    const payload = {
      packageId: packageId,
      fileId: fileId,
      fileType: fileType,
    };

    const response = await SessionsApi.deletePackageSessionFile(token, payload);

    if (response.status && fileType === "packageImage") {
      setCurrentPackageFile((prev) => ({ ...prev, image: null }));
      setNewPackageFiles((prev) => ({ ...prev, image: null }));
    }
    if (response.status && fileType === "packageVideo") {
      setCurrentPackageFile((prev) => ({ ...prev, video: null }));
      setNewPackageFiles((prev) => ({ ...prev, video: null }));
    }
  };

  const getPackageTax = () => {
    if (!specializationsData || !userData || !userData.specialistIds) return;
    if (!newPackageData.specializations.length)
      return userData.specialistIds[0].taxCategory.packageTaxPercent!;
    const specializationId = specializationsData.find(
      (el) => el.value === newPackageData.specializations[0]
    )!.mainSpecializationRef;
    const taxPercent = userData.specialistIds.find(
      (el) => el.mainSpecializations[0]._id === specializationId
    )?.taxCategory.packageTaxPercent;
    return taxPercent;
  };

  if (actionLoaders.generalLoader) {
    return (
      <div className={s.loaderContainer}>
        <Loader size={100} />
      </div>
    );
  }

  return (
    <div className={s.container}>
      <div className={s.leftCotextBlock}>
        {userData!.preferredLanguages &&
          userData!.preferredLanguages.length > 1 && (
            <div className={s.whiteBlock}>
              <div className={s.langTabBlock}>
                {userData!.preferredLanguages.map((item, index) => (
                  <div
                    key={index}
                    className={
                      availableLangs.selectLang === ISO639LangReversed[item]
                        ? s.activeLangTab
                        : s.notActiveLangTab
                    }
                    onClick={() =>
                      setAvailableLangs((prev) => ({
                        ...prev,
                        selectLang: ISO639LangReversed[item],
                      }))
                    }
                  >
                    {
                      //@ts-expect-error
                      strings[item]
                    }
                    {availableLangs.selectLang === ISO639LangReversed[item] && (
                      <span className={s.langTabIndicator}></span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        <div className={s.whiteBlock}>
          <h2 className={s.blockHead}>{strings.basicInfo}</h2>
          <Input
            inputValue={
              newPackageData.title.find(
                (el) => el.language === availableLangs.selectLang
              )?.text!
            }
            onChangeInput={(value) =>
              setNewPackageData((prev) => ({
                ...prev,
                title: prev.title.map((el) =>
                  el.language === availableLangs.selectLang
                    ? { ...el, text: value }
                    : el
                ),
              }))
            }
            bgColor="white"
            border="1px solid #DADADA"
            placeholder={strings.titleOfPackage + "..."}
            lable={strings.titleOfPackage}
            isVisible
            showRequiredStar
          />
          <div className={s.descriptionBlock}>
            <span className={s.dataLabel}>
              {strings.descriptionOfPackage}
              <span className={s.requiredStar}>*</span>
            </span>
            <EditorProvider>
              <Editor
                value={
                  newPackageData.description.find(
                    (el) => el.language === availableLangs.selectLang
                  )?.text
                }
                onChange={(e) => {
                  if (
                    e.target.value.length >= 600 ||
                    e.target.value.includes("<img src=")
                  )
                    return;
                  setNewPackageData((prev) => ({
                    ...prev,
                    description: prev.description.map((el) =>
                      el.language === availableLangs.selectLang
                        ? { ...el, text: e.target.value }
                        : el
                    ),
                  }));
                }}
                containerProps={{
                  style: { resize: "vertical", borderRadius: "20px" },
                }}
              >
                <Toolbar
                  style={{
                    backgroundColor: "white",
                    borderBottom: "1px solid #DADADA",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px 10px",
                  }}
                >
                  <div className={s.toolsBlock}>
                    {window.innerWidth > 768 && (
                      <>
                        <BtnUndo />
                        <BtnRedo />
                        <Separator />
                      </>
                    )}
                    <BtnBold />
                    <BtnItalic />
                    <BtnUnderline />
                    <BtnStrikeThrough />
                    <Separator />
                    <BtnNumberedList />
                    <BtnBulletList />
                    <BtnClearFormatting />
                    <Separator />
                    {/*  <BtnStyles /> */}
                  </div>
                  <span className={s.charactersLimit}>
                    600 {strings.characters}
                  </span>
                </Toolbar>
              </Editor>
            </EditorProvider>
          </div>
        </div>
        <div className={s.whiteBlock}>
          <h2 className={s.blockHead}>{strings.packageIntroVideo}</h2>
          <div className={s.videoUploadBlock}>
            <span>{strings.packageVideoLable}</span>
            <div className={s.fileInputBlock}>
              {(newPackageFiles.video || currentPackageFile.video) && (
                <div className={s.uploadedVideoNameBlock}>
                  {newPackageFiles.video
                    ? newPackageFiles.video.name
                    : currentPackageFile.video!.name}
                  <Delete
                    className={s.deleteIcon}
                    onClick={
                      currentPackageFile.video
                        ? () =>
                            deleteFile(
                              currentPackageFile.video!._id,
                              "packageVideo"
                            )
                        : () =>
                            setNewPackageFiles((prev) => ({
                              ...prev,
                              video: null,
                            }))
                    }
                  />
                </div>
              )}
              <label>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept={videoExtensions.join(", ")}
                  onChange={(e) => handleFileChange(e, "video")}
                  multiple={false}
                />
                {newPackageFiles.video || currentPackageFile.video ? (
                  <div className={s.refreshFileBtn} role="button">
                    <Refresh className={s.refreshIcon} />
                    {windowWidth > 768 ? strings.clickToChange : ""}
                  </div>
                ) : (
                  <div className={s.uplaodFileBtn}>
                    <Upload />
                    {strings.uplaodVideo}
                  </div>
                )}
              </label>
            </div>
          </div>
          <div className={s.imageUploadBlock}>
            <span>{strings.imgPreviewVideo}</span>
            <div className={s.imageInputBlock}>
              {(newPackageFiles.image || currentPackageFile.image) && (
                <div className={s.imageBlock}>
                  <img
                    src={
                      newPackageFiles.image
                        ? URL.createObjectURL(newPackageFiles.image)
                        : currentPackageFile.image?.link
                    }
                    className={s.videoPreviewImg}
                  />
                  <div
                    className={s.deleteImageBlock}
                    onClick={
                      currentPackageFile.image
                        ? () =>
                            deleteFile(
                              currentPackageFile.image!._id,
                              "packageImage"
                            )
                        : () =>
                            setNewPackageFiles((prev) => ({
                              ...prev,
                              image: null,
                            }))
                    }
                  >
                    <Delete className={s.deleteIcon} />
                  </div>
                </div>
              )}

              <label className={s.fileInputBlock}>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept={imageExtensions.join(", ")}
                  onChange={(e) => handleFileChange(e, "image")}
                  multiple={false}
                />
                {newPackageFiles.image || currentPackageFile.image ? (
                  <div className={s.refreshFileBtn} role="button">
                    <Refresh className={s.refreshIcon} />
                    {windowWidth > 768 ? strings.clickToChange : ""}
                  </div>
                ) : (
                  <div className={s.uplaodFileBtn}>
                    <Upload />
                    {strings.uplaodImage}
                  </div>
                )}
              </label>
            </div>
          </div>
        </div>
        <div className={s.whiteBlock}>
          <div className={s.packageSessionsHeadBlock}>
            <h2 className={s.blockHead}>
              {strings.packageSessions}
              <span className={s.requiredStar}>*</span>
            </h2>
            <div className={s.generalSessionsInfoBlock}>
              <div className={s.sessionInfoTitleBlock}>
                <div className={s.generalSessionInfoElement}>
                  <Stickynote className={s.sessionsInfoIcon} />
                  {newPackageData.sessions.length + " " + strings.sections}
                </div>
                <div className={s.generalSessionInfoElement}>
                  <Clock className={s.sessionsInfoIcon} />
                  {getAllSessionDuration() + " " + strings.hoursLength}
                </div>
              </div>
              {newPackageData.sessions.length < 100 && (
                <div
                  role="button"
                  className={s.addSectionBtn}
                  onClick={() =>
                    setNewPackageData((prev) => ({
                      ...prev,
                      sessions: [
                        ...prev.sessions,
                        {
                          id: newPackageData.sessions.length + 1,
                          title: emtyTextFieldArray,
                          description: emtyTextFieldArray,
                          datesAndTime: [
                            {
                              duration: 60,
                            },
                          ],
                          order: newPackageData.sessions.length + 1,
                          nextSessionGap: prev.sessions.length
                            ? {
                                min: prev.sessions[prev.sessions.length - 1]
                                  .nextSessionGap.min,
                                max: prev.sessions[prev.sessions.length - 1]
                                  .nextSessionGap.max,
                              }
                            : { min: 1, max: 7 },
                          specialization: tagsDirection
                            ? tagsDirection
                            : userData &&
                              userData.specialistIds &&
                              userData.specialistIds.length === 1
                            ? directions[
                                userData!
                                  .specialistIds!.map((item) => ({
                                    _id: item.mainSpecializations[0]._id,
                                    specialization:
                                      item.mainSpecializations[0]
                                        .specialization,
                                  }))
                                  .find(
                                    (el) =>
                                      el._id ===
                                      userData.specialistIds![0]
                                        .mainSpecializations[0]._id
                                  )!.specialization
                              ]
                            : "",
                        },
                      ],
                    }))
                  }
                >
                  {strings.addNewSection}
                </div>
              )}
            </div>
          </div>
          <VirtualList
            className="virtual-list"
            dataKey="id"
            dataSource={newPackageData.sessions}
            handle="#handle-drag"
            onDrop={onDrop}
            keeps={100}
          >
            {(
              record: {
                id: number;
                title: {
                  language: string;
                  text: string;
                }[];
                description?: {
                  language: string;
                  text: string;
                }[];
                datesAndTime: {
                  duration: number;
                }[];
                order: number;
                nextSessionGap: { min: number; max: number };
                specialization: SpecialistTagsDirections | string;
              },
              index: number
            ) => {
              return (
                <div className={s.cardBlock} key={index}>
                  <MenuIcon id="handle-drag" className={s.menuIcon} />
                  <PackageSessionCard
                    session={record}
                    setSessionState={setNewPackageData}
                    selectLang={availableLangs.selectLang}
                  />
                </div>
              );
            }}
          </VirtualList>
        </div>
      </div>
      <div className={s.rightContentBlock}>
        <div className={`${s.whiteBlock} ${s.publishBlock}`}>
          <div className={s.mainActionsBlock}>
            <StandartButton
              action={!packageId ? createNewPackage : updatePackage}
              buttonTitle={
                packageId ? strings.specSaveProfileBtn : strings.publishBtn
              }
              width="100%"
            />
            <div
              role="btn"
              className={s.showPreviewBtn}
              onClick={() => setPreviewPackageModal(true)}
            >
              {strings.ViewPreview}
            </div>
          </div>
        </div>
        <div className={s.whiteBlock}>
          <h2 className={s.blockHead}>{strings.packStatus}</h2>
          <div>
            <span>{strings.productStatus}</span>
            <MultipleSelect
              data={packageStatusData}
              multiplie={false}
              setValue={(value) =>
                setNewPackageData((prev) => ({
                  ...prev,
                  isActive: value === "false" ? false : true,
                }))
              }
              value={newPackageData.isActive.toString()}
              bgColor="white"
              border="1px solid #DADADA"
              padding="10px 20px"
            />
          </div>
        </div>
        <div className={s.whiteBlock}>
          <h2 className={s.blockHead}>{strings.packCategotias}</h2>
          {specializationsData &&
            userData &&
            userData.specialistIds &&
            userData.specialistIds.length > 1 && (
              <div className={s.specializationsBlock}>
                <span>
                  {strings.specializationsPackage}
                  <span className={s.requiredStar}>*</span>
                </span>
                <MultipleSelect
                  data={specializationsData}
                  multiplie={false}
                  setValue={(value) => {
                    if (value !== newPackageData.specializations[0]) {
                      setNewPackageData((prev) => ({
                        ...prev,
                        directions: [],
                      }));
                    }
                    setTagsDirection(
                      specializationsData.find((el) => el.value === value)!
                        .specialization
                    );
                    setNewPackageData((prev) => ({
                      ...prev,
                      specializations: [value] as string[],
                    }));
                  }}
                  value={newPackageData.specializations}
                  bgColor="white"
                  border="1px solid #DADADA"
                  padding="10px 20px"
                />
              </div>
            )}

          <div className={s.specializationsBlock}>
            <span>
              {strings.diractionTagLable}
              <span className={s.requiredStar}>*</span>
            </span>
            <MultipleSelect
              data={tags ?? []}
              multiplie={true}
              setValue={(value) => {
                setNewPackageData((prev) => ({
                  ...prev,
                  directions: value as string[],
                }));
              }}
              value={newPackageData.directions}
              bgColor="white"
              border="1px solid #DADADA"
              padding="10px 20px"
              disabled={!tags || !tags.length ? true : false}
              chip
            />
          </div>
          <div>
            <span>{strings.packageProjectLable}</span>
            <MultipleSelect
              data={
                projectsData
                  ? [
                      ...projectsData,
                      { value: "false", lable: strings.withoutProject },
                    ]
                  : []
              }
              multiplie={false}
              setValue={(value) =>
                setNewPackageData((prev) => ({
                  ...prev,
                  project: value === "false" ? false : (value as string),
                }))
              }
              value={
                newPackageData.project ? newPackageData.project.toString() : ""
              }
              bgColor="white"
              border="1px solid #DADADA"
              padding="10px 20px"
              disabled={!projectsData || !projectsData.length ? true : false}
            />
          </div>
          {/*   <div className={s.tagsBlock}>
            <span>{strings.tags + " (" + strings.upTo + " " + "8)"}</span>
            <div className={s.inputTagsBlock}>
              <input
                type="text"
                value={tagsInput}
                onChange={(e) => handleTagsInputChange(e.target.value)}
                className={s.tagsInput}
                placeholder={strings.tagsForSearch}
              />
              {newPackageData.tags.length < 8 && (
                <div
                  className={s.plusTagBtn}
                  onClick={
                    tagsInput.length
                      ? () => addTagHandle(tagsInput)
                      : () => null
                  }
                >
                  <Plus
                    className={s.plusIcon}
                    style={{ opacity: tagsInput.length ? 1 : 0.5 }}
                  />
                </div>
              )}
              {newPackageData.tags.length ? (
                <div className={s.addedTagsList}>
                  {newPackageData.tags.map((item, index) => (
                    <div className={s.tagElement} key={index}>
                      <span>#{item}</span>
                      <Close
                        className={s.closeIcon}
                        onClick={() =>
                          setNewPackageData((prev) => ({
                            ...prev,
                            tags: prev.tags.filter((_, i) => i !== index),
                          }))
                        }
                      />
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div> */}
        </div>

        <div className={s.whiteBlock}>
          <h2 className={s.blockHead}>{strings.packPricing}</h2>

          <div className={s.priceInputBlock}>
            <Input
              inputValue={newPackageData.packageCost.toString()}
              type="number"
              onChangeInput={(value) =>
                setNewPackageData((prev) => ({
                  ...prev,
                  packageCost: Number(value),
                }))
              }
              isVisible
              showRequiredStar
              bgColor="white"
              border="1px solid #DADADA"
              placeholder="45"
              lable={strings.packPrice}
            />
            <span className={s.descriptionTitle}>
              {"(" +
                strings.formatString(strings.packageCommission, {
                  tax: getPackageTax()!,
                }) +
                ")"}
            </span>
          </div>
        </div>
      </div>

      <ModalWindow
        isOpen={previewPackageModal}
        setIsOpen={setPreviewPackageModal}
        bgColor="var(--gray-bg-color)"
        width="90vw"
      >
        <PackageInfo
          propsPackageData={newPackageData}
          propsPackageFiles={{
            video: newPackageFiles.video
              ? URL.createObjectURL(newPackageFiles.video)
              : currentPackageFile.video
              ? currentPackageFile.video.link
              : undefined,
            image: newPackageFiles.image
              ? URL.createObjectURL(newPackageFiles.image)
              : currentPackageFile.image
              ? currentPackageFile.image.link
              : undefined,
          }}
        />
      </ModalWindow>
      <div className={s.mobileSaveBtn}>
        <StandartButton
          action={!packageId ? createNewPackage : updatePackage}
          buttonTitle={
            packageId ? strings.specSaveProfileBtn : strings.publishBtn
          }
          width="100%"
        />
      </div>
    </div>
  );
};

export default PackagesTool;
