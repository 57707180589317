import { ISO639Lang } from "../../../../../constans/languagesList";
import strings from "../../../../../localization";
import s from "./LangTabs.module.css";

type LangTabsProps = {
  languagesData: { language: string; selected: boolean }[];
  onClickFunc: (lang: string) => void;
};

const LangTabs = ({ languagesData, onClickFunc }: LangTabsProps) => {
  return (
    <div className={s.langTabsBlock}>
      {languagesData.map((item, index) => (
        <div
          className={item.selected ? s.selectedLangTab : s.langTab}
          key={index}
          style={{
            borderRightWidth: index === languagesData.length - 1 ? "0" : "1px",
          }}
          role="button"
          onClick={() => onClickFunc(item.language)}
        >
          {
            //@ts-expect-error
            strings[ISO639Lang[item.language]]
          }
        </div>
      ))}
    </div>
  );
};

export default LangTabs;
