import { Dispatch, SetStateAction, useEffect } from "react";
import StripeApi from "../../../../../api/stripeApi";
import Input from "../../../../../components/Input/Input";
import MultipleSelect from "../../../../../components/MultipleSelect/MultipleSelect";
import strings from "../../../../../localization";
import { Currencies, PayoutInfo } from "../../../../../types/Payout";
import s from "./Payout.module.css";

type Payout = {
  payoutInfo: Omit<PayoutInfo, "user" | "_id">;
  setPayoutInfo: Dispatch<SetStateAction<Omit<PayoutInfo, "user" | "_id">>>;
};

const Payout = ({ payoutInfo, setPayoutInfo }: Payout) => {
  const token = localStorage.getItem("token");

  const currencyOptions = Object.entries(Currencies).map(([key, value]) => ({
    lable: key,
    value,
  }));

  const validateField = (field: keyof Omit<PayoutInfo, "user" | "_id">) => {
    if (payoutInfo[field].length) return true;
    return false;
  };

  useEffect(() => {
    (async () => {
      if (!token) return;
      const response = await StripeApi.getPayoutInfo(token);
      if (response.status && response.payoutInfo) {
        setPayoutInfo(response.payoutInfo);
      }
    })();
  }, []);

  return (
    <div className={s.container}>
      <h2 className={s.blockTitle}>{strings.withdrawalFunds}</h2>
      <Input
        inputValue={payoutInfo.title}
        isVisible
        onChangeInput={(value) =>
          setPayoutInfo((prev) => ({ ...prev, title: value }))
        }
        lable={strings.payoutTitle}
        border={
          !validateField("title")
            ? "1px solid #ec8b8b"
            : "1px solid transparent"
        }
      />
      <Input
        inputValue={payoutInfo.bankAccount}
        isVisible
        onChangeInput={(value) =>
          setPayoutInfo((prev) => ({ ...prev, bankAccount: value }))
        }
        lable={strings.bankAccount}
        border={
          !validateField("bankAccount")
            ? "1px solid #ec8b8b"
            : "1px solid transparent"
        }
      />
      <Input
        inputValue={payoutInfo.itin}
        isVisible
        onChangeInput={(value) =>
          setPayoutInfo((prev) => ({ ...prev, itin: value }))
        }
        lable={strings.itin}
        border={
          !validateField("itin") ? "1px solid #ec8b8b" : "1px solid transparent"
        }
      />
      <MultipleSelect
        data={currencyOptions}
        multiplie={false}
        setValue={(value) =>
          setPayoutInfo((prev) => ({ ...prev, currency: value as Currencies }))
        }
        value={payoutInfo.currency}
        lable={strings.currency}
        padding={"10px"}
        border={
          !validateField("currency")
            ? "1px solid #ec8b8b"
            : "1px solid transparent"
        }
      />
    </div>
  );
};

export default Payout;
