import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SubscriptionsAPI from "../../api/subscriptionsApi";
import TreeQuizApi from "../../api/treeQuizesApi";
import { ReactComponent as Refresh } from "../../assets/SpecTools/refresh.svg";
import FinishImg from "../../assets/TreeQuiz/finish.png";
import StartImg from "../../assets/TreeQuiz/welcome.png";
import Loader from "../../components/Loader/Loader";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import Replenish from "../../components/Replenish/Replenish";
import SpecialistCard from "../../components/SpecialistCard/SpecialistCard";
import StandartButton from "../../components/StandartButton/StandartButton";
import StripeProvider from "../../components/StripeProvider/StripeProvider";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { TreeQuizQuestion } from "../../types/TreeQuiz";
import { SessionPackage } from "../../types/TypeSession";
import { TypeUserData } from "../../types/TypeUsers";
import { getTranslation } from "../../utils/getTranslation";
import Notify from "../../utils/toaster";
import AnswerElement from "./AnswerElement/AnswerElement";
import s from "./TreeQuiz.module.css";

type TreeQuizProps = {
  isInitialPageContainer?: boolean;
  containerHeight?: string;
};

const TreeQuiz = ({
  isInitialPageContainer,
  containerHeight,
}: TreeQuizProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeQuestion, setActiveQuestion] = useState<
    TreeQuizQuestion | undefined
  >();
  const [treeQuizId, setTreeQuizId] = useState<string | undefined>();
  const [answersResult, setAnswersResult] = useState<string[]>([]);
  const [isStartQuiz, setIsStartQuiz] = useState(true);
  const [recomendationSpecData, setRecomendationSpecData] = useState<
    (TypeUserData & { package?: SessionPackage })[] | undefined
  >();
  const [recommendationPaggination, setRecommendationPaggination] = useState({
    page: 1,
    limit: 5,
    hasMore: false,
    laoder: false,
  });
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [subscriptionPayload, setSubscriptionPayload] = useState<
    | {
        subscriptionId: string;
        daysAmount: number;
        redirectUrl: string;
      }
    | undefined
  >();
  const [projectId, setProjectId] = useState<string | undefined>();
  const [lookingForRealTimeSession, setLookingForRealTimeSession] = useState<
    boolean | undefined
  >();
  const [actionLaoders, setActionLaoders] = useState({
    saveAnswerLoader: false,
  });
  const [quizProgress, setQuizProgress] = useState(0);

  useEffect(() => {
    const makeAsync = async () => {
      if (
        !token ||
        !isStartQuiz ||
        location.search.includes("showRecomendetions=true")
      )
        return;
      const response = await TreeQuizApi.getRecommendationQuiz(token);

      if (response.status && response.treeQuiz) {
        setQuizProgress(0);
        setTreeQuizId(response.treeQuiz._id);
        setActiveQuestion(response.treeQuiz.firstQuestion);
      }
    };
    makeAsync();
  }, [isStartQuiz, location]);

  const quizResult = async () => {
    if (!token || !treeQuizId) return;
    const response = await TreeQuizApi.getTreeQuizResult(token);
    if (response.status && response.treeQuizResult) {
      setLookingForRealTimeSession(
        response.treeQuizResult.lookingForRealTimeSession
      );
      setProjectId(response.treeQuizResult.project);
    }

    const activeSubscriptionResponse =
      await SubscriptionsAPI.getMyActiveSubscription(token);
    if (
      response.treeQuizResult &&
      response.treeQuizResult.choosedSubscriptionDuration &&
      response.treeQuizResult.subscriptionId &&
      activeSubscriptionResponse.status
    ) {
      if (
        activeSubscriptionResponse.subscription &&
        activeSubscriptionResponse.subscription.subscription._id ===
          response.treeQuizResult.subscriptionId
      ) {
        return true;
      }
      if (
        activeSubscriptionResponse.subscription &&
        activeSubscriptionResponse.subscription.subscription._id !==
          response.treeQuizResult.subscriptionId
      ) {
        Notify("Unexpected error");
        navigate("home");
        return false;
      }

      setSubscriptionPayload({
        subscriptionId: response.treeQuizResult.subscriptionId,
        daysAmount: response.treeQuizResult.choosedSubscriptionDuration,
        redirectUrl:
          process.env.REACT_APP_BASE_URL + "tree-quiz?showRecomendetions=true",
      });
      setSubscriptionModal(true);
      return false;
    }
    return true;
  };

  const finishQuiz = async () => {
    const shouldRedirect = await quizResult();
    if (shouldRedirect) updateQueryParams();
    setActiveQuestion(undefined);
    setRecommendationPaggination({
      page: 1,
      limit: 5,
      hasMore: false,
      laoder: false,
    });
    setRecomendationSpecData(undefined);
    setAnswersResult([]);
  };

  const updateQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("showRecomendetions", "true");
    queryParams.delete("oldParam");
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const saveAnswerResult = async () => {
    if (!token || !activeQuestion) return;
    setActionLaoders((prev) => ({ ...prev, saveAnswerLoader: true }));
    const respnse = await TreeQuizApi.saveTreeQuizAnswerResult(
      token,
      answersResult,
      activeQuestion._id
    );
    setActionLaoders((prev) => ({ ...prev, saveAnswerLoader: false }));

    if (respnse.status && respnse.nextQuestion) {
      setActiveQuestion(respnse.nextQuestion);
      setAnswersResult([]);
    }
    if (respnse.progress !== undefined) {
      setQuizProgress(respnse.progress);
    }
    if (!respnse.nextQuestion) {
      await finishQuiz();

      return;
    }
  };

  const getRecommendetionsFromQuiz = async () => {
    if (!token) return;
    setRecommendationPaggination((prev) => ({ ...prev, laoder: true }));
    const response = await TreeQuizApi.getRecommendationsFromTreeQuiz(
      token,
      recommendationPaggination.page,
      recommendationPaggination.limit,
      recomendationSpecData && recomendationSpecData.map((item) => item._id)
    );

    setRecommendationPaggination((prev) => ({ ...prev, laoder: false }));
    if (response.status && response.recommendations) {
      setRecommendationPaggination((prev) => ({
        ...prev,
        page: prev.page + 1,
        hasMore:
          response.recommendations!.length === recommendationPaggination.limit,
      }));
      setRecomendationSpecData((prev) =>
        prev
          ? [...prev, ...response.recommendations!]
          : response.recommendations
      );

      setActiveQuestion(undefined);
    }
  };
  useEffect(() => {
    const checkProjectId = async () => {
      if (!token) return;
      const response = await TreeQuizApi.getTreeQuizResult(token);
      if (response.status && response.treeQuizResult) {
        setProjectId(response.treeQuizResult.project);
      }
    };
    if (location.search.includes("showRecomendetions=true")) {
      getRecommendetionsFromQuiz();
      checkProjectId();
      setIsStartQuiz(true);
    }
  }, [location]);

  const backAnswerHandler = async () => {
    if (!token || !userData) return;
    const response = await TreeQuizApi.cancelTreeQuizAnswerResult(token, {
      userId: userData._id,
    });

    if (
      response.status &&
      response.nextQuestion &&
      response.progress !== undefined
    ) {
      setActiveQuestion(response.nextQuestion);
      setAnswersResult([]);
      setQuizProgress(response.progress);
    }
  };

  return (
    <div
      className={s.container}
      style={{
        width: isInitialPageContainer ? "100%" : "calc(100% - 40px)",
        height: containerHeight ?? "calc(100% - 20px)",
      }}
    >
      {!isStartQuiz && !location.search.includes("showRecomendetions=true") && (
        <div className={s.startBlock}>
          <img src={StartImg} alt="" className={s.startImg} />
          <h1 className={s.startTitle}>{strings.startQuizTitle}</h1>
          <span className={s.startDescription}>
            {strings.startQuizDescription}
          </span>
          <div className={s.buttonBlock}>
            <StandartButton
              action={() => setIsStartQuiz(true)}
              buttonTitle={strings.quizStartBtn}
              width="100%"
            />
          </div>
        </div>
      )}
      {isStartQuiz && activeQuestion && (
        <div
          className={`${s.quizContent} ${
            isInitialPageContainer && s.initialPageQuizContent
          }`}
        >
          <div className={s.progressBarBlock}>
            <span
              className={s.dynamicProgresLine}
              style={{ width: `${quizProgress}%` }}
            ></span>
          </div>
          {activeQuestion && (
            <p className={s.questionTitle}>
              {
                activeQuestion.labels.find(
                  (el) => el.language === userData?.selectedLanguage
                )?.text
              }
            </p>
          )}
          <div className={s.answerList}>
            {activeQuestion &&
              activeQuestion.answers.map((answer: any) => (
                <AnswerElement
                  key={answer._id}
                  answer={answer}
                  isMultiSelect={activeQuestion.multiselect}
                  isSelectAnswer={answersResult.includes(answer._id)}
                  setAnswersResult={setAnswersResult}
                />
              ))}
          </div>
          <div className={s.buttonBlock}>
            <StandartButton
              action={backAnswerHandler}
              buttonTitle={strings.quizBackBtn}
              width="50%"
              disabled={quizProgress === 0 ? true : false}
            />
            <StandartButton
              action={saveAnswerResult}
              buttonTitle={strings.quizNextBtn}
              width="50%"
              disabled={
                answersResult.length && !actionLaoders.saveAnswerLoader
                  ? false
                  : true
              }
            />
          </div>
        </div>
      )}
      {recomendationSpecData &&
        location.search.includes("showRecomendetions=true") && (
          <div
            className={
              isInitialPageContainer
                ? s.initialRecommendetionsContent
                : s.recommendetionsContent
            }
          >
            <div
              className={
                isInitialPageContainer
                  ? s.initialPageRegreshBtn
                  : s.refreshButton
              }
              onClick={() => {
                navigate(`${location.pathname}`);
                setQuizProgress(0);
              }}
            >
              <Refresh className={s.refreshIcon} />
              <span>{strings.quizAgain}</span>
            </div>

            <img
              src={FinishImg}
              alt=""
              className={s.finishImg}
              style={{ display: isInitialPageContainer ? "none" : "block" }}
            />
            <h1 className={s.startTitle}>{strings.great}</h1>
            <span className={s.startDescription}>{strings.specForYou}</span>
            <div className={s.specListBlock}>
              {recomendationSpecData.map((item, index) => (
                <div key={index} className={s.specCardBlock}>
                  <SpecialistCard
                    fullName={
                      getTranslation(item.name, userData?.selectedLanguage) +
                      " " +
                      getTranslation(item.surname, userData?.selectedLanguage)
                    }
                    languages={
                      item.preferredLanguages ? item.preferredLanguages : []
                    }
                    location={item.location ? item.location : ""}
                    mainSpeciality={item.specialistIds!.map(
                      (item) =>
                        item.mainSpecializations[0].labels.find(
                          (el) => el.language === userData?.selectedLanguage
                        )!.text
                    )}
                    userId={item._id}
                    isRecommendationCard={window.innerWidth >= 768}
                    showActionButtons={true}
                    avatarHeight="100%"
                    avatarBlockBorderRadius={
                      window.innerWidth >= 768 ? "20px 0 0 20px" : undefined
                    }
                    mainInfoBorderRadius={
                      window.innerWidth >= 768 ? "0 20px 20px 0" : undefined
                    }
                    projectId={projectId}
                    briefDescription={
                      item.briefDescription &&
                      item.briefDescription[0] &&
                      (item.briefDescription?.find(
                        (el) => el.language === userData?.selectedLanguage
                      )?.text ??
                        item.briefDescription[0].text)
                    }
                    receiveRealTimeOffers={lookingForRealTimeSession}
                    specialistId={item.specialistIds![0]._id}
                    recommendedSpecData={item.specialistIds}
                    sessionPackage={item.package}
                    isFromTreeQuiz={true}
                  />
                </div>
              ))}
              {recommendationPaggination.hasMore &&
              !recommendationPaggination.laoder ? (
                <div
                  className={s.showMoreBtn}
                  role="button"
                  onClick={getRecommendetionsFromQuiz}
                >
                  {strings.openMoreBtn}
                </div>
              ) : (
                recommendationPaggination.laoder && (
                  <div className={s.pagLoader}>
                    <Loader size={50} />
                  </div>
                )
              )}
            </div>
          </div>
        )}
      <ModalWindow
        isOpen={subscriptionModal}
        setIsOpen={setSubscriptionModal}
        width="fit-content"
        maxWidth="fit-content"
        alternativeCloseFunction={
          !isInitialPageContainer
            ? () => navigate("/home")
            : () => {
                setSubscriptionModal(false);
                navigate(`${location.pathname}`);
                setQuizProgress(0);
              }
        }
      >
        <div className={s.subscriptionModalContent}>
          <StripeProvider>
            <Replenish
              isPaymentSession
              hidePromocode
              subscriptionPayload={subscriptionPayload}
            />
          </StripeProvider>
        </div>
      </ModalWindow>
    </div>
  );
};
export default TreeQuiz;
