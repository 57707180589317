import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import NextSlideIcon from "../../../assets/SpecialistSetupInfo/next-slide.svg";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import AboutSubStep1 from "./AboutSubSteps/AboutSubStep1";
import AboutSubStep2 from "./AboutSubSteps/AboutSubStep2";
import AboutSubStep3 from "./AboutSubSteps/AboutSubStep3";
import s from "./AboutYourself.module.css";

import SpecialistApi from "../../../api/specialistApi";
import UsersApi from "../../../api/usersApi";
import { ISO639LangReversed } from "../../../constans/languagesList";
import strings from "../../../localization";
import { areAllTextFieldsFilled } from "../../../utils/setupProfileInfo";
import Notify from "../../../utils/toaster";

type CreatingProfileProps = {
  setActiveStep: Dispatch<SetStateAction<number>>;
  subStep: { current: number; total: number };
  setSubStep: Dispatch<SetStateAction<{ current: number; total: number }>>;
  setLoader: Dispatch<SetStateAction<boolean>>;
};

const AboutYourself = ({
  setActiveStep,
  subStep,
  setSubStep,
  setLoader,
}: CreatingProfileProps) => {
  const { userData } = useContext(ContextProvider);
  const [newBriefDescription, setNewBriefDescription] = useState<
    { text: string; language: string; selected: boolean }[] | undefined
  >();
  const [newAboutMyself, setNewAboutMyself] = useState<
    { text: string; language: string; selected: boolean }[] | undefined
  >();
  const [newAboutTeraphy, setNewAboutTeraphy] = useState<
    { text: string; language: string; selected: boolean }[] | undefined
  >();

  useEffect(() => {
    if (!userData || !userData.preferredLanguages) return;
    const emptyText = userData.preferredLanguages.map((item, index) => ({
      text: "",
      language: ISO639LangReversed[item],
      selected: index === 0,
    }));
    setNewBriefDescription(emptyText);
    setNewAboutMyself(emptyText);
    setNewAboutTeraphy(emptyText);
  }, [userData]);

  const nextStep = async () => {
    const token = localStorage.getItem("token");

    if (
      token &&
      userData?.specialistIds &&
      (userData?.specialistIds ||
        (userData?.specialistIds && userData?.specialistIds[0]._id))
    ) {
      const specialistId = userData?.specialistIds[0]._id
        ? userData?.specialistIds[0]._id
        : userData.specialistIds.toString();
      setLoader(true);
      if (subStep.current === 1) {
        if (!areAllTextFieldsFilled(newAboutMyself)) {
          setLoader(false);
          return Notify(strings.fillAllLangInfoError);
        }
        await SpecialistApi.update(
          {
            _id: specialistId,
            aboutMyself:
              newAboutMyself &&
              newAboutMyself.map((item) => ({
                text: item.text,
                language: item.language,
              })),
          },
          token
        );

        setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
      } else if (subStep.current === 2) {
        if (!areAllTextFieldsFilled(newAboutTeraphy)) {
          setLoader(false);
          return Notify(strings.fillAllLangInfoError);
        }
        await SpecialistApi.update(
          {
            _id: specialistId,
            aboutTherapy:
              newAboutTeraphy &&
              newAboutTeraphy.map((item) => ({
                text: item.text,
                language: item.language,
              })),
          },
          token
        );
        setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
      } else if (subStep.current === 3) {
        if (!areAllTextFieldsFilled(newBriefDescription)) {
          setLoader(false);
          return Notify(strings.fillAllLangInfoError);
        }
        await UsersApi.updateUserData(token, {
          _id: userData._id,
          briefDescription:
            newBriefDescription &&
            newBriefDescription.map((item) => ({
              text: item.text,
              language: item.language,
            })),
        });
        setActiveStep((prev) => prev + 1);
      }
      setLoader(false);
    }
  };
  return (
    <div>
      {subStep.current === 1 && newAboutMyself && (
        <AboutSubStep1
          maxLenght={1500}
          textareaValue={newAboutMyself}
          setTextareaValue={setNewAboutMyself}
        />
      )}
      {subStep.current === 2 && newAboutTeraphy && (
        <AboutSubStep2
          maxLenght={1500}
          setTextareaValue={setNewAboutTeraphy}
          textareaValue={newAboutTeraphy}
        />
      )}
      {subStep.current === 3 && newBriefDescription && (
        <AboutSubStep3
          maxLenght={250}
          setTextareaValue={setNewBriefDescription}
          textareaValue={newBriefDescription}
        />
      )}
      <div className={s.continueButtonBlock}>
        {subStep.current === subStep.total ? (
          <StandartButton
            buttonTitle={strings.continueBtnMob}
            action={nextStep}
            width="250px"
          />
        ) : (
          <div role="button" className={s.nextButtonBlock}>
            <img
              className={s.nextImage}
              src={NextSlideIcon}
              alt=""
              onClick={nextStep}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AboutYourself;
