import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SubscriptionsAPI from "../../api/subscriptionsApi";
import SubsLogoIcon from "../../assets/Subscriptions/subscription-logo.png";
import { ContextProvider } from "../../contextProvider";
import {
  Subscription,
  SubscriptionFeatures,
  UserSubscription,
} from "../../types/Subscription";
import s from "./Subscriptions.module.css";

import { ReactComponent as ArrowRight } from "../../assets/HomePage/chevron-up.svg";
import { ReactComponent as CloseIcon } from "../../assets/HomePage/close.svg";
import Loader from "../../components/Loader/Loader";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import PaymentSession from "../../components/PaymentSession/PaymentSession";
import strings from "../../localization";
import { daysUntilEnds, formatDateAndTime } from "../../utils/dateActions";
import { calculateDiscountedPrice } from "../../utils/Numbers";
import PeriodCard from "./PeriodCard/PeriodCard";
import SubscriptionCard from "./SubscriptionCard/SubscriptionCard";

type SubscriptionsProps = {
  setModalVisible: Dispatch<SetStateAction<boolean>>;
};
const geticonStreamString =
  process.env.REACT_APP_HTTP_CONNECTION_STRING +
  "subscriptions/getSubscriptionIconStream/";
const Subscriptions = ({ setModalVisible }: SubscriptionsProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [subscriptionsData, setSubscriptionsData] = useState<
    Subscription[] | null
  >(null);
  const [selectedSubscrip, setSelectedSubscrip] = useState<Subscription | null>(
    null
  );
  const [subscriptionStep, setSubscriptionStep] = useState(1);
  const [selectedPeriod, setSelectedPeriod] = useState<{
    days: number;
    amount: number;
  } | null>(null);

  const [activeSubscription, setActiveSubscription] =
    useState<UserSubscription | null>(null);
  const [actionLoaders, setActionLoaders] = useState({
    getAllSubsLoader: false,
    getActiveSubLoader: false,
  });
  const [selectedFeatureData, setSelectedFeatureData] =
    useState<SubscriptionFeatures[]>();
  const [paymentModal, setPaymentModal] = useState(false);

  const SvgIcon = ({ url }: { url: string }) => {
    fetch(url)
      .then((response) => response.text())
      .then((svgString) => {
        document.getElementById(url)!.innerHTML = svgString;
      })
      .catch((error) => console.error("Error fetching SVG:", error));

    return <div id={url} className={s.infoIcon}></div>;
  };

  const getAllSubscriptions = async () => {
    if (!token) return;
    const payload = {
      isOn: true,
      page: 1,
      limit: 10,
    };
    setActionLoaders((prev) => ({ ...prev, getAllSubsLoader: true }));
    const response = await SubscriptionsAPI.getAllSubscriptions(token, payload);

    if (response.status && response.subscriptions) {
      setSubscriptionsData(response.subscriptions);
      setActionLoaders((prev) => ({ ...prev, getAllSubsLoader: false }));
    }
  };

  useEffect(() => {
    getAllSubscriptions();
  }, []);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token) return;
      setActionLoaders((prev) => ({ ...prev, getActiveSubLoader: true }));
      const response = await SubscriptionsAPI.getMyActiveSubscription(token);
      setActionLoaders((prev) => ({ ...prev, getActiveSubLoader: false }));
      if (response.status && response.subscription) {
        setActiveSubscription(response.subscription);
      }
    };
    makeAsync();
  }, []);

  return (
    <div className={s.container}>
      <div className={s.header}>
        {subscriptionStep === 2 || selectedFeatureData ? (
          <div
            className={s.backBtn}
            onClick={() => {
              setSubscriptionStep(1);
              setSelectedFeatureData(undefined);
            }}
          >
            <ArrowRight className={s.arrowIcon} />
            {strings.backPackageBtn}
          </div>
        ) : (
          <div></div>
        )}
        <div className={s.closeBlock} onClick={() => setModalVisible(false)}>
          <CloseIcon className={s.closeIcon} />
        </div>
      </div>
      <img src={SubsLogoIcon} className={s.subsLogo} />
      <div className={s.headTitle}>
        <h2>Talzi</h2>
        <h2>Premium</h2>
      </div>
      {actionLoaders.getActiveSubLoader || actionLoaders.getActiveSubLoader ? (
        <div className={s.mainLoaderBlock}>
          <Loader size={50} />
        </div>
      ) : activeSubscription ? (
        <div className={s.activeSubBlock}>
          <div className={s.planDescription}>
            {strings.activeSubDescription +
              " " +
              formatDateAndTime(
                new Date(activeSubscription.startDate),
                userData!.selectedLanguage
              ).formattedDate}
          </div>
          <div className={s.leftSubDaysBlock}>
            <div className={s.activeSubTitle}>
              {activeSubscription.subscription.title}
            </div>
            <div className={s.daysUntilEndBlock}>
              {daysUntilEnds(new Date(activeSubscription.nextPayDate)) +
                " " +
                strings.daysLeft}
            </div>
          </div>
          {selectedFeatureData ? (
            <div className={s.featuresList}>
              {selectedFeatureData.map((item, index) => (
                <div className={s.specialistLvlBlock}>
                  <div className={s.leftInfoBlock} key={index}>
                    {item.icon ? (
                      <SvgIcon url={`${geticonStreamString}${item.icon}`} />
                    ) : (
                      <div className={s.infoIcon}></div>
                    )}
                    <div className={s.infoCardTextBlock}>
                      <span>
                        {
                          item.titleLabels.find(
                            (el) => el.language === userData!.selectedLanguage
                          )?.text!
                        }
                      </span>
                      <span>
                        {" "}
                        {
                          item.descriptionLabels.find(
                            (el) => el.language === userData!.selectedLanguage
                          )?.text!
                        }
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={s.infoBlock}>
              {activeSubscription.subscription.shortInfoList.map(
                (item, index) => (
                  <div
                    className={s.specialistLvlBlock}
                    onClick={() => setSelectedFeatureData(item.features)}
                    key={index}
                  >
                    <div className={s.leftInfoBlock} key={index}>
                      {item.icon ? (
                        <SvgIcon url={`${geticonStreamString}${item.icon}`} />
                      ) : (
                        <div className={s.infoIcon}></div>
                      )}
                      <div className={s.infoCardTextBlock}>
                        <span>
                          {
                            item.titleLabels.find(
                              (el) => el.language === userData!.selectedLanguage
                            )?.text!
                          }
                        </span>
                        <span>
                          {" "}
                          {
                            item.descriptionLabels.find(
                              (el) => el.language === userData!.selectedLanguage
                            )?.text!
                          }
                        </span>
                      </div>
                    </div>
                    <ArrowRight className={s.infoArrowRight} />
                  </div>
                )
              )}
            </div>
          )}
          <div className={s.nextChatgeBlock}>
            <span className={s.nextChargeTitle}>
              {strings.nextCharge +
                " " +
                formatDateAndTime(
                  new Date(activeSubscription.nextPayDate),
                  userData!.selectedLanguage
                ).formattedDate}
            </span>
          </div>
        </div>
      ) : (
        <div className={s.chooseSubscriptionBlock}>
          {selectedFeatureData && subscriptionStep === 1 ? (
            <div className={s.featuresList}>
              {selectedFeatureData.map((item, index) => (
                <div className={s.specialistLvlBlock} key={index}>
                  <div className={s.leftInfoBlock} key={index}>
                    {item.icon ? (
                      <SvgIcon url={`${geticonStreamString}${item.icon}`} />
                    ) : (
                      <div className={s.infoIcon}></div>
                    )}
                    <div className={s.infoCardTextBlock}>
                      <span>
                        {
                          item.titleLabels.find(
                            (el) => el.language === userData!.selectedLanguage
                          )?.text!
                        }
                      </span>
                      <span>
                        {" "}
                        {
                          item.descriptionLabels.find(
                            (el) => el.language === userData!.selectedLanguage
                          )?.text!
                        }
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : subscriptionStep === 1 ? (
            <div className={s.stepBlock}>
              <div className={s.planDescription}>
                {subscriptionsData && subscriptionsData.length
                  ? strings.planDescription
                  : strings.noSubscriptions}
              </div>
              {subscriptionsData && subscriptionsData.length ? (
                <div className={s.subsListBlock}>
                  {subscriptionsData.map((item, index) => (
                    <SubscriptionCard
                      selectedSubscrip={selectedSubscrip}
                      subscriptionData={item}
                      setSelectedSubscrip={setSelectedSubscrip}
                      key={index}
                    />
                  ))}
                </div>
              ) : null}
              {selectedSubscrip && (
                <div className={s.infoBlock}>
                  {selectedSubscrip.shortInfoList.map((item, index) => (
                    <div
                      key={index}
                      className={s.specialistLvlBlock}
                      onClick={() => setSelectedFeatureData(item.features)}
                    >
                      <div className={s.leftInfoBlock} key={index}>
                        {item.icon ? (
                          <SvgIcon url={`${geticonStreamString}${item.icon}`} />
                        ) : (
                          <div className={s.infoIcon}></div>
                        )}
                        <div className={s.infoCardTextBlock}>
                          <span>
                            {
                              item.titleLabels.find(
                                (el) =>
                                  el.language === userData!.selectedLanguage
                              )?.text!
                            }
                          </span>
                          <span>
                            {" "}
                            {
                              item.descriptionLabels.find(
                                (el) =>
                                  el.language === userData!.selectedLanguage
                              )?.text!
                            }
                          </span>
                        </div>
                      </div>
                      <ArrowRight className={s.infoArrowRight} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className={s.stepBlock}>
              <div className={s.planDescription}>{strings.chooseSubPeriod}</div>
              {selectedSubscrip && (
                <div className={s.periodListBlock}>
                  {selectedSubscrip.discountsForSubscription.map(
                    (item, index) => (
                      <PeriodCard
                        periodData={item}
                        subscriptionData={selectedSubscrip}
                        daysAmount={selectedPeriod}
                        setDaysAmount={setSelectedPeriod}
                        key={index}
                      />
                    )
                  )}
                </div>
              )}
            </div>
          )}

          {subscriptionStep === 1 ? (
            <button
              className={s.continueBtn}
              disabled={selectedSubscrip ? false : true}
              style={{ opacity: selectedSubscrip ? 1 : 0.5 }}
              onClick={() => {
                setSelectedPeriod(
                  selectedSubscrip!.discountsForSubscription[0]
                );
                setSelectedFeatureData(undefined);
                setSubscriptionStep((prev) => prev + 1);
              }}
            >
              {strings.continueSubscripBtn}
            </button>
          ) : (
            <button
              className={s.continueBtn}
              disabled={selectedSubscrip ? false : true}
              style={{ opacity: selectedSubscrip ? 1 : 0.5 }}
              onClick={() => setPaymentModal(true)}
            >
              {strings.payFor +
                "  " +
                calculateDiscountedPrice(
                  selectedSubscrip!.costPerMonth *
                    Number((selectedPeriod!.days / 30).toFixed(0)),
                  selectedPeriod!.amount
                )}{" "}
              $
            </button>
          )}
        </div>
      )}
      {selectedSubscrip && selectedPeriod && (
        <ModalWindow
          isOpen={paymentModal}
          setIsOpen={setPaymentModal}
          width="fit-content"
        >
          <PaymentSession
            hidePromocode
            subscriptionPayload={{
              subscriptionId: selectedSubscrip._id,
              daysAmount: selectedPeriod.days,
            }}
          />
        </ModalWindow>
      )}
    </div>
  );
};

export default Subscriptions;
