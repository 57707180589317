import { Dispatch, SetStateAction } from "react";
import SpecialistApi from "../api/specialistApi";
import UsersApi from "../api/usersApi";
import { PlaceType } from "../components/CitySelect/CitySelect";
import strings from "../localization";
import { TypeUpdatedUserInfo } from "../types/TypeUsers";

export const generateNumbersArray = (start: number, end: number) => {
  var arrayOfStrings = [];
  for (var i = start; i <= end; i++) {
    arrayOfStrings.push(String(i));
  }
  return arrayOfStrings;
};
export const daysList = generateNumbersArray(1, 31).map((item) => ({
  value: item,
  lable: item,
  color: "black",
}));

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const monthList = generateNumbersArray(1, 12).map((item, index) => {
  return {
    value: item,
    lable: monthNames[index],
  };
});
export const yearsList = generateNumbersArray(1950, 2024).map((item) => ({
  value: item,
  lable: item,
}));
const uploadIntroVideoToBucket = async (
  language: string,
  video: File,
  token: string
) => {
  const formData = new FormData();
  formData.append("file", video);
  formData.append("language", language);
  const response = await SpecialistApi.uploadIntroVideoToBucket(
    token,
    formData
  );
  return response;
};
export const updateSpecialistIntroVideo = async (
  specialistId: string,
  filesData: { language: string; video: File }[],
  token: string
) => {
  const uploadVideosToBucketPromise = filesData.map((item) => {
    return uploadIntroVideoToBucket(item.language, item.video, token);
  });
  const uploadResult = await Promise.all(uploadVideosToBucketPromise);
  const payload = {
    specialistId: specialistId,
    files: uploadResult.map((res) => ({
      language: res.language!,
      video: res.video!,
    })),
  };
  const response = await SpecialistApi.uploadIntroVideo(payload, token);
  return response;
};

export const updateUserAvatar = async (
  avatar: File,
  userId: string,
  token: string
) => {
  const formData = new FormData();
  formData.append("avatar", avatar);

  const response = await UsersApi.updateAvatar(token, formData, userId);

  return response;
};

export const addSpecialistCertificates = async (
  certificates: File[],
  specialistId: string,
  token: string
) => {
  const formData = new FormData();
  for (let i = 0; i < certificates.length; i++) {
    formData.append("files", certificates[i]);
  }
  formData.append("specialistId", specialistId);
  const response = await SpecialistApi.uploadCertificates(
    specialistId,
    formData,

    token
  );
  return response;
};

interface TextInputValues {
  latinName: string;
  latinSurname: string;
  name: string;
  surname: string;
  practics: string;
  experience: string;
}

const isTextInputValueValid = (value: string) => value.trim() !== "";

export const areAllTextFieldsFilled = (
  array: { text: string; language: string }[] | undefined
): boolean => {
  if (!array) return false;
  return array.every((element) => element.text.length > 0);
};

export const areAllStatesValid = (
  name: { text: string; language: string }[] | undefined,
  surname: { text: string; language: string }[] | undefined,
  textInputValues: TextInputValues,
  selectedCountry: string,
  selectedCity: PlaceType | null,
  selectedLangs: string | string[]
): { status: boolean; error?: string } => {
  const isNameValid = areAllTextFieldsFilled(name);
  const isSurnameValid = areAllTextFieldsFilled(surname);
  const isPracticsValid = isTextInputValueValid(textInputValues.practics);
  const isExperienceValid = isTextInputValueValid(textInputValues.experience);

  const isCountryValid = isTextInputValueValid(selectedCountry);
  const isCityValid = selectedCity !== null;

  const areLangsValid = (): boolean => {
    if (Array.isArray(selectedLangs)) {
      return selectedLangs.length > 0;
    }

    return isTextInputValueValid(selectedLangs);
  };

  if (!isNameValid || !isSurnameValid) {
    return { status: false, error: strings.nameSurnameError };
  }
  if (!isPracticsValid) {
    return { status: false, error: strings.practicHoursError };
  }
  if (!isExperienceValid) {
    return { status: false, error: strings.experienceError };
  }
  if (!isCountryValid || !isCityValid) {
    return { status: false, error: strings.locationError };
  }
  if (!areLangsValid()) {
    return { status: false, error: strings.languagesError };
  }
  return { status: true };
};

export const calculateAge = (birthDate: Date): number => {
  const currentDate: Date = new Date();
  let age: number = currentDate.getFullYear() - birthDate.getFullYear();
  const currentMonth: number = currentDate.getMonth();
  const birthMonth: number = birthDate.getMonth();

  if (
    currentMonth < birthMonth ||
    (currentMonth === birthMonth && currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const formatDate = (date: Date): string => {
  const day: number = date.getDate();
  const month: number = date.getMonth() + 1;
  const year: number = date.getFullYear();

  const formattedDay: string = day < 10 ? "0" + day : "" + day;
  const formattedMonth: string = month < 10 ? "0" + month : "" + month;

  return formattedDay + "." + formattedMonth + "." + year;
};

const setValidationErrorForSpecProfile = (
  errorValue: string,
  errorLable: string,
  setError: Dispatch<SetStateAction<{ value: string; lable: string }[]>>,
  isShownError: boolean
) => {
  if (isShownError) {
    setError((prev) => {
      if (prev.map((item) => item.value).includes(errorValue)) {
        return prev;
      }
      return [
        ...prev,
        {
          value: errorValue,
          lable: errorLable,
        },
      ];
    });
  } else {
    setError((prev) => prev.filter((el) => el.value !== errorValue));
  }
};
export const validationSpecProfileUpdating = (
  setError: Dispatch<SetStateAction<{ value: string; lable: string }[]>>,
  specialistUpdateData: TypeUpdatedUserInfo,
  userUpdateData: TypeUpdatedUserInfo
) => {
  if (specialistUpdateData.subSpecializations) {
    setValidationErrorForSpecProfile(
      "subSpecializations",
      strings.inccorectSubSpecializations,
      setError,
      specialistUpdateData.subSpecializations.length < 1
    );
  }
  if (userUpdateData.name) {
    const isError = areAllTextFieldsFilled(userUpdateData.name);

    setValidationErrorForSpecProfile(
      "nameSurname",
      strings.inorrectNameSurname,
      setError,
      !isError
    );
  }
  if (userUpdateData.surname) {
    const isError = areAllTextFieldsFilled(userUpdateData.surname);
    setValidationErrorForSpecProfile(
      "nameSurname",
      strings.inorrectNameSurname,
      setError,
      !isError
    );
  }
  if (userUpdateData.preferredLanguages) {
    setValidationErrorForSpecProfile(
      "preferredLanguages",
      strings.incorrectLanguage,
      setError,
      userUpdateData.preferredLanguages.length < 1
    );
  }
};
