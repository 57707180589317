import { TypeUserData } from "./TypeUsers";

export enum Currencies {
  UAH = "uah",
  EUR = "eur",
  USD = "usd",
  PLN = "pln",
}

export type PayoutInfo = {
  _id: string;
  user: TypeUserData;
  bankAccount: string; //Расчетный счет
  itin: string; //Код ЄДРПОУ/IНН
  title: string;
  currency: Currencies;
};
