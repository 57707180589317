import { Radio } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Checkbox from "react-custom-checkbox";
import SpecialistApi from "../../../api/specialistApi";
import Checkicon from "../../../assets/SpecialistSetupInfo/checked.svg";
import NextSlideIcon from "../../../assets/SpecialistSetupInfo/next-slide.svg";
import RadioButtonsGroup from "../../../components/RadioGroup/RadioGroup";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { SpecialistData, SpecialistLevel } from "../../../types/TypeSpecialist";
import Notify from "../../../utils/toaster";
import s from "./SpecializationStep.module.css";

type TypespecializationList = {
  _id: string;
  title: string;
  checked: boolean;
};
type MainSpecialiszationsData = {
  value: string;
  lable: string;
};
type SpecializationStepProps = {
  setActiveStep: Dispatch<SetStateAction<number>>;
  subStep: { current: number; total: number };
  setSubStep: Dispatch<SetStateAction<{ current: number; total: number }>>;
  setLoader: Dispatch<SetStateAction<boolean>>;
  specialistInfo: SpecialistData | null;
  setSpecialistInfo: Dispatch<SetStateAction<SpecialistData | null>>;
  checkedSpecializations: TypespecializationList[];
  setCheckedSpecializations: Dispatch<SetStateAction<TypespecializationList[]>>;
};
const SpecializationStep = ({
  setActiveStep,
  subStep,
  setSubStep,
  setLoader,
  setSpecialistInfo,
  checkedSpecializations,
  setCheckedSpecializations,
  specialistInfo,
}: SpecializationStepProps) => {
  const token = localStorage.getItem("token");
  const { userData, setUserData } = useContext(ContextProvider);

  const [mainSpecializationsData, setMainSpecializationsData] = useState<
    MainSpecialiszationsData[]
  >([]);
  const [mainSpecValue, setMainSpecValue] = useState(
    mainSpecializationsData.length ? mainSpecializationsData[0].value : ""
  );
  const [levelsData, setLevelsData] = useState<SpecialistLevel[] | undefined>();
  const [selectedLevel, setSelectedLevel] = useState<string | undefined>();

  useEffect(() => {
    if (
      specialistInfo &&
      specialistInfo.mainSpecializations &&
      specialistInfo.mainSpecializations.length
    ) {
      getSubSpecializations(specialistInfo.mainSpecializations[0]._id);
    }

    const makeAsync = async () => {
      if (token) {
        const mainSpecializationsResponse =
          await SpecialistApi.getMainSpecializations(token);

        if (
          mainSpecializationsResponse.status &&
          mainSpecializationsResponse.mainSpecializations
        ) {
          setMainSpecializationsData(
            mainSpecializationsResponse.mainSpecializations.map((item) => ({
              value: item._id,
              lable: item.labels.find(
                (item) => item.language === userData?.selectedLanguage
              )?.text!,
            }))
          );
        }
      }
    };
    makeAsync();
  }, [userData]);

  const handleNext = async () => {
    const selectedSpecializations =
      checkedSpecializations &&
      checkedSpecializations
        .filter((item) => item.checked)
        .map((item) => item._id);
    if (selectedSpecializations && selectedSpecializations.length === 0)
      return Notify(strings.chooseSpeciality);
    if (
      subStep.current === 3 &&
      levelsData &&
      levelsData.length &&
      (!selectedLevel || !selectedLevel.length)
    ) {
      return Notify(strings.chooseLevel);
    }
    if (token && userData && userData.specialistIds) {
      setLoader(true);

      const specialistId = userData.specialistIds[0]._id
        ? userData.specialistIds[0]._id
        : userData.specialistIds[0].toString();

      const response = await SpecialistApi.update(
        {
          _id: specialistId,
          subSpecializations: selectedSpecializations,
          level: selectedLevel,
        },
        token
      );

      if (response.status) setSpecialistInfo(response.specialistData);
      setActiveStep((prev) => prev + 1);
      setLoader(false);
    }
  };

  const getSubSpecializations = async (mainSpec: string) => {
    if (token && userData) {
      const subSpecResponse = await SpecialistApi.getSubSpecializations(token, [
        mainSpec,
      ]);

      if (subSpecResponse.status && subSpecResponse.subSpecializations) {
        const subSpecData = subSpecResponse.subSpecializations.map((item) => ({
          _id: item._id,
          title: item.labels.find(
            (item) => item.language === userData.selectedLanguage
          )?.text!,
          checked: false,
        }));

        setCheckedSpecializations(subSpecData);
      }
    }
  };

  const handleNextSubStep = async () => {
    const selectedSpecializations =
      checkedSpecializations &&
      checkedSpecializations
        .filter((item) => item.checked)
        .map((item) => item._id);

    if (subStep.current === 2) {
      if (!selectedSpecializations.length) {
        return Notify(strings.chooseSpeciality);
      }
      setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
      return getLevels();
    }
    if (mainSpecValue === "" && subStep.current === 1)
      return Notify(strings.chooseSpeciality);
    if (token && userData && userData.specialistIds) {
      setLoader(true);

      if (subStep.current === 1) {
        const specialistId = userData.specialistIds[0]._id
          ? userData.specialistIds[0]._id
          : userData.specialistIds[0].toString();
        const response = await SpecialistApi.update(
          {
            _id: specialistId,
            mainSpecializations: [mainSpecValue],
          },
          token
        );

        if (response.status) {
          setUserData((prev) => ({
            ...prev!,
            specialistIds: [response.specialistData],
          }));
          getSubSpecializations(mainSpecValue);
          setSpecialistInfo(response.specialistData);
          setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
          setLoader(false);
        }
      } else {
        setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
        setLoader(false);
      }
    }
  };

  const getLevels = async () => {
    if (
      !userData ||
      !userData.specialistIds ||
      !userData.specialistIds[0].mainSpecializations ||
      !userData.specialistIds[0].mainSpecializations.length
    )
      return;

    const payload = {
      mainSpecId: userData.specialistIds[0].mainSpecializations[0]._id,
    };
    const response = await SpecialistApi.getAllSpecialistLevels(payload);

    if (response.status && response.levels && response.levels.length) {
      setLevelsData(response.levels);
    }

    if ((!response.levels || !response.levels.length) && mainSpecValue.length) {
      handleNext();
    }
  };
  useEffect(() => {
    getLevels();
  }, [userData]);

  return (
    <div className={s.container}>
      {subStep.current === 1 ? (
        <div className={s.stebContainer}>
          <h4 className={s.mainTitle}>{strings.primaryProfTitle}</h4>
          <span className={s.secondaryTitle}>{strings.choosePrimaryProf}</span>
          <RadioButtonsGroup
            data={mainSpecializationsData}
            defaultValue={
              mainSpecializationsData && mainSpecializationsData[0]
                ? mainSpecializationsData[0].value
                : ""
            }
            value={mainSpecValue}
            setValue={setMainSpecValue}
            flexDirection="column"
            width="100%"
          />
        </div>
      ) : subStep.current === 2 ? (
        <div className={s.stebContainer}>
          <h4 className={s.mainTitle}>{strings.chooseOnesThat}</h4>
          <p className={s.secondaryTitle}>{strings.youCanChoose}</p>
          {checkedSpecializations &&
            checkedSpecializations.map((item, index) => (
              <Checkbox
                key={index}
                containerStyle={{ marginBottom: "30px", cursor: "pointer" }}
                checked={item.checked}
                icon={
                  <img
                    src={Checkicon}
                    style={{
                      width: 25,
                      marginBottom: "10px",
                      marginLeft: "8px",
                    }}
                    alt=""
                  />
                }
                borderColor={item.checked ? "#3FB083" : "black"}
                borderRadius={5}
                size={20}
                label={item.title}
                labelStyle={{ fontSize: "18px", marginLeft: "15px" }}
                onChange={(checked: boolean) =>
                  setCheckedSpecializations(
                    (prev) =>
                      prev &&
                      prev.map((item, i) =>
                        i === index ? { ...item, checked } : item
                      )
                  )
                }
              />
            ))}
        </div>
      ) : (
        <div className={s.stebContainer}>
          <h4 className={s.mainTitle}>{strings.levelStepTitle}</h4>
          <p className={s.secondaryTitle}>{strings.levelStepDescriptuion}</p>
          {levelsData &&
            levelsData.map((item, index) => (
              <div
                key={index}
                className={s.selectLevelBlock}
                onClick={() => setSelectedLevel(item._id)}
              >
                <Radio
                  checked={selectedLevel === item._id}
                  sx={{
                    color: "black",
                    "&.Mui-checked": {
                      color: "#3FB083",
                    },
                  }}
                />

                <div className={s.levelTextBlock}>
                  <span>
                    {
                      item.title.find(
                        (el) => el.language === userData?.selectedLanguage
                      )?.text
                    }
                  </span>
                  <span>
                    {
                      item.description.find(
                        (el) => el.language === userData?.selectedLanguage
                      )?.text
                    }
                  </span>
                </div>
              </div>
            ))}
        </div>
      )}
      <div className={s.continueButtonBlock}>
        {subStep.current === subStep.total ? (
          <StandartButton
            buttonTitle={strings.continueBtnSetupProfile}
            action={handleNext}
            width="315px"
          />
        ) : (
          <div
            role="button"
            className={s.substepButton}
            onClick={handleNextSubStep}
          >
            <img src={NextSlideIcon} alt="" />
          </div>
        )}
      </div>
      <div className={s.mobileContinueButton}>
        <StandartButton
          action={
            subStep.current === subStep.total ? handleNext : handleNextSubStep
          }
          buttonTitle={strings.continueBtnSetupProfile}
          width="70%"
        />
      </div>
    </div>
  );
};

export default SpecializationStep;
