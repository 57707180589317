import strings from "../../../../localization";
import { DiscountTypes } from "../../../../types/TypeSpecialist";
import styles from "./SessionPrices.module.css";

type Price = {
  language: string;
  amount: number;
  oldAmount?: number;
  note?: string;
};

type SessionPricesProps = {
  prices: Price[];
  discountType?: DiscountTypes;
};

const calculateSavings = (oldAmount: number, amount: number) =>
  oldAmount - amount;

const SessionPrices = ({ prices, discountType }: SessionPricesProps) => {
  const priceDescriptions = {
    [DiscountTypes.PROJECT]: strings.projectDiscountDescription,
    [DiscountTypes.SELECTION]: strings.selectionDiscountDescription,
    [DiscountTypes.SUBSCRIPTION]: strings.subscriptionDiscountDescription,
  };

  return (
    <div className={styles.priceBlock}>
      {discountType && (
        <div className={styles.priceDescription}>
          {priceDescriptions[discountType]}
        </div>
      )}

      <div className={styles.priceMultiple}>
        {prices.map((price, index) => (
          <div
            className={
              prices.length > 4 ? styles.wrapPriceitem : styles.priceItem
            }
            key={index}
          >
            <div className={styles.language}>{price.language}</div>
            <div className={styles.price}>${price.amount}</div>
            {price.oldAmount && (
              <div>
                <span className={styles.oldPrice}>${price.oldAmount}</span>
                <span className={styles.savings}>
                  {" "}
                  Save ${calculateSavings(price.oldAmount, price.amount)}
                </span>
              </div>
            )}
            {price.note && <div className={styles.priceNote}>{price.note}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SessionPrices;
