import { ReactComponent as BellIcon } from "../assets/Header/bell.svg";
import { ReactComponent as ChatIcon } from "../assets/Header/chatIcon.svg";

export const leftHeaderMenu = [
  /*   {
    icon: CalendarIcon,
    title: "calendar",
    path: "/calendar",
  }, */
  {
    icon: ChatIcon,
    title: "global-chat",
    path: "/global-chat",
  },
  {
    icon: BellIcon,
    title: "notifies",
  },
];
