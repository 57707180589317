import StripeApi from "../../../../../api/stripeApi";
import { Currencies, PayoutInfo } from "../../../../../types/Payout";

const validateAllFields = (
  fields: Omit<PayoutInfo, "user" | "_id">
): boolean => {
  return Object.values(fields).every(
    (value) => value && value.toString().trim().length > 0
  );
};

export const savePayoutInfo = async (
  token: string | null,
  payoutInfo: Omit<PayoutInfo, "user" | "_id">
) => {
  if (!token || !validateAllFields(payoutInfo)) return;

  await StripeApi.savePayoutInfo(token, payoutInfo);
};

export const emptyPayoutInfo = {
  bankAccount: "",
  itin: "",
  title: "",
  currency: "" as Currencies,
};
