import { CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "../../utils/theme";
type LoaderProps = {
  size?: number;
};
const Loader = ({ size }: LoaderProps) => {
  const defaultSize = window.innerWidth > 768 ? 150 : 50;
  return (
    <ThemeProvider theme={theme}>
      <CircularProgress size={size ?? defaultSize} color="primary" />
    </ThemeProvider>
  );
};

export default Loader;
