import { CircularProgress, ThemeProvider } from "@mui/material";
import countries from "i18n-iso-countries";
import { SetStateAction, useContext, useEffect, useRef, useState } from "react";
import SpecialistApi from "../../api/specialistApi";
import UsersApi from "../../api/usersApi";
import { ReactComponent as ArrowBack } from "../../assets/Chat/arrow-right.svg";
import FilterIcon from "../../assets/Company/filter-icon.svg";
import { ReactComponent as MagnifierIcon } from "../../assets/Company/magnifier.svg";
import { ReactComponent as NoSpecIcon } from "../../assets/SpecialistCard/noSpecialist.svg";
import Input from "../../components/Input/Input";
import MuiSwitch from "../../components/MuiSwitch/MuiSwitch";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import SpecialistCard from "../../components/SpecialistCard/SpecialistCard";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { SpecialistLevels } from "../../types/Subscription";
import { TypeUserData } from "../../types/TypeUsers";
import { findDifferenceToMakeMultiple } from "../../utils/general";
import getAvailableParams from "../../utils/getAvailableParams";
import { getTranslation } from "../../utils/getTranslation";
import {
  getExtraElements,
  removeFilterElements,
} from "../../utils/specialistFilter";
import { theme } from "../../utils/theme";
import "./SpecialistFindPage.css";
import s from "./SpecialistFindPage.module.css";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const genderSelectData = [
  {
    value: "Male",
    lable: "Male",
  },
  {
    value: "Female",
    lable: "Female",
  },
  {
    value: "Non-binary",
    lable: "Non-binary",
  },
];
type SelectData = {
  value: string;
  lable: string;
};
type SpecializationsState = {
  mainSpecData: SelectData[];
  subSpecData?: SelectData[];
  selectedMainSpec?: { value: string | string[]; label: string | string[] };
  selectedSubSpec?: { value: string | string[]; label: string | string[] };
};

const SpecialistFindPage = () => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const filterRef = useRef<any>(null);
  const specialistListRef = useRef<HTMLDivElement | null>(null);
  const headFilterBlockRef = useRef<HTMLDivElement | null>(null);
  const [isLoader, setIsLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<{
    code: string;
    title: string;
  }>({ code: "", title: "" });
  const [selectedCity, setSelectedCity] = useState<string | string[]>([]);
  const [selectedLangs, setSelectedLangs] = useState<string[] | undefined>();
  const [specializations, setSpecializations] = useState<SpecializationsState>({
    mainSpecData: [],
  });
  const [languagesList, setLanguagesList] = useState<
    { value: string; lable: string }[]
  >([]);

  const [genderFilterValue, setGenderFilterValue] = useState<
    string | undefined
  >();
  const [timeFilterValue, setTimeFilterValue] = useState<string | undefined>();
  const [availableGenders, setAvailableGenders] = useState<
    | {
        value: string;
        lable: string;
      }[]
    | null
  >(null);
  const [specialistData, setSpecialistData] = useState<
    TypeUserData[] | undefined
  >();
  const [pagginatuions, setPagginations] = useState({
    total: 0,
    currentPage: 1,
    hasMore: true,
  });
  const [realTimeSessionsChecked, setRealTimeSessionsChecked] = useState<
    boolean | null
  >(null);
  const [selectedLevel, setSelectedLevel] = useState<
    SpecialistLevels | undefined
  >();
  /*   const [sort, setSort] = useState<string | undefined>(); */
  const [ageRange, setAgeRange] = useState<string | undefined>();
  const [filterUpdateCounter, setFilterUpdateCounter] = useState(0);
  const [tagsData, setTagsData] = useState<SelectData[] | undefined>();
  const [selectedTags, setSelectedTags] = useState<string[] | undefined>();
  const [isFavourite, setIsFavourite] = useState<boolean | undefined>();
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  const clearFilters = () => {
    setSpecialistData([]);
    setSearch("");
    setSelectedCountry({ code: "", title: "" });
    setSelectedCity([]);
    setSpecializations((prev) => ({
      ...prev,
      selectedMainSpec: undefined,
      selectedSubSpec: undefined,
    }));
    setGenderFilterValue(undefined);
    setSelectedTags(undefined);
    setSelectedLevel(undefined);
    setTimeFilterValue(undefined);
    /*     setSort(undefined); */
    setAgeRange(undefined);
    setRealTimeSessionsChecked(null);
    setSelectedLangs(undefined);
    setPagginations((prev) => ({ ...prev, currentPage: 1 }));
    setFilterUpdateCounter((prev) => prev + 1);
  };

  const ageRangesData = [
    { lable: strings.upToFilters + " 25", value: "1 24" },
    { lable: "25 - 30", value: "25 30" },
    { lable: "30 - 40", value: "30 40" },
    { lable: "40 - 50", value: "40 50" },
    { lable: "50 " + strings.andMoreFilter, value: "50 1000" },
  ];

  const timeFiltersData = [
    {
      value: "06:00-12:00",
      lable: strings.morning,
    },
    {
      value: "12:00-18:00",
      lable: strings.afternoon,
    },
    {
      value: "18:00-23:59",
      lable: strings.evening,
    },
    {
      value: "all",
      lable: strings.filterAnyTime,
    },
  ];

  const levelsData = [
    { lable: strings.specLvlStudent, value: SpecialistLevels.STUDENT },
    {
      lable: strings.specLvlGradtudent,
      value: SpecialistLevels.GRADUATED_STUDENT,
    },
    { lable: strings.specLvl1, value: SpecialistLevels.LEVEL1 },
    { lable: strings.specLvl2, value: SpecialistLevels.LEVEL2 },
  ];

  useEffect(() => {
    const makeAsync = async () => {
      if (token && userData && filterUpdateCounter !== 1 && !isLoader) {
        if (pagginatuions.currentPage === 1) {
          setSpecialistData([]);
        }

        const payload = {
          userId: userData._id,
          filters: {
            name: search,
            country:
              selectedCountry && selectedCountry.title
                ? [selectedCountry.title]
                : undefined,
            city:
              selectedCity && selectedCity.length ? selectedCity : undefined,
            gender: genderFilterValue ? [genderFilterValue] : undefined,
            age: {
              from: ageRange ? Number(ageRange.split(" ")[0]) : 1,
              to: ageRange ? Number(ageRange.split(" ")[1]) : 1000,
            },
            preferredLanguages: selectedLangs,

            specialistId: {
              showInCatalog: true,
              subSpecializations:
                specializations.selectedSubSpec &&
                specializations.selectedSubSpec.value.length
                  ? [specializations.selectedSubSpec.value as string]
                  : undefined,
              mainSpecializations:
                specializations.selectedMainSpec &&
                specializations.selectedMainSpec.value.length
                  ? [specializations.selectedMainSpec.value as string]
                  : undefined,
              isVerified: true,
              level: selectedLevel && [selectedLevel],
              tags:
                selectedTags && selectedTags.length ? selectedTags : undefined,
            },
            time: timeFilterValue,
            page: pagginatuions.currentPage,
            limit: window.innerWidth < 1900 ? 6 : 8,
            considerOverlaps: true,
            receiveRealTimeOffers:
              realTimeSessionsChecked !== null
                ? realTimeSessionsChecked
                : undefined,
            /*  sort: sort && {
              field: sort.split(" ")[1],
              type: Number(sort?.split(" ")[0]),
            }, */
            IsFavourite: isFavourite,
          },
          alreadyFetched:
            specialistData &&
            specialistData.length &&
            pagginatuions.currentPage > 1
              ? specialistData.map((item) => item._id)
              : undefined,
        };

        setIsLoader(true);
        const response = await SpecialistApi.getFilteredSpecialists(
          token,
          payload
        );

        if (response.paramsAvailable) {
          setTagsData(
            response.paramsAvailable.tags
              .map((item) => ({
                lable: item.labels.find(
                  (el) => el.language === userData.selectedLanguage
                )?.text!,
                value: item._id,
              }))
              .sort((a, b) => a.lable.localeCompare(b.lable))
          );
        }

        if (
          response.users &&
          !response.users.length &&
          pagginatuions.currentPage === 1
        ) {
          setSpecialistData(undefined);
        }

        if (
          response.status &&
          response.paramsAvailable &&
          response.users &&
          Array.isArray(response.users)
        ) {
          setPagginations((prev) => ({
            ...prev,
            hasMore:
              response.users!.length === (window.innerWidth < 1900 ? 6 : 8),
          }));
          setSpecialistData((prev) =>
            prev ? [...prev, ...response.users!] : [...response.users!]
          );
        }
        setIsLoader(false);
        if (response.users && !response.users.length) return;

        const [genders, mainSpecs, subSpecs, languages] =
          await getAvailableParams(token, payload.filters);

        const mainSpecResposne = await SpecialistApi.getMainSpecializations(
          token
        );
        if (mainSpecResposne.status && mainSpecResposne.mainSpecializations) {
          const availManSpecs = mainSpecResposne.mainSpecializations
            .filter((item) => mainSpecs.includes(item.specialization))
            .map((item) => ({
              lable: item.labels.find(
                (item) => item.language === userData.selectedLanguage
              )?.text!,
              value: item.specialization,
            }));

          setSpecializations((prev) =>
            prev
              ? { ...prev, mainSpecData: availManSpecs }
              : { mainSpecData: availManSpecs }
          );

          const relatedMainSpec = mainSpecResposne.mainSpecializations.map(
            (item) => item._id
          );
          const subSpecResponse = await SpecialistApi.getSubSpecializations(
            token,
            relatedMainSpec
          );
          if (!subSpecResponse.status || !subSpecResponse.subSpecializations)
            return;
          const availSubSpecs = subSpecResponse.subSpecializations
            .filter((item) => subSpecs.includes(item.specialization))
            .map((item) => ({
              lable: item.labels.find(
                (item) => item.language === userData.selectedLanguage
              )?.text!,
              value: item.specialization,
            }));

          setSpecializations((prev) =>
            prev
              ? { ...prev, mainSpecData: availManSpecs }
              : { mainSpecData: availManSpecs }
          );

          setSpecializations((prev) =>
            prev
              ? { ...prev, subSpecData: availSubSpecs }
              : {
                  mainSpecData: availManSpecs,
                  subSpecData: availSubSpecs,
                }
          );
        }

        const gendersResponse = await UsersApi.getAllGenders(token);
        if (gendersResponse.status && gendersResponse.genders) {
          const availGenders = gendersResponse.genders
            .filter((item) => genders.includes(item.gender))
            .map((item) => ({
              lable: item.labels.find(
                (item) => item.language === userData.selectedLanguage
              )?.text!,
              value: item.gender,
            }));

          setAvailableGenders(availGenders);
        }

        if (
          selectedLangs &&
          removeFilterElements(
            getExtraElements(selectedLangs as string[], languages),
            selectedLangs
          ).length !== selectedLangs.length
        ) {
          setSelectedLangs(
            removeFilterElements(
              getExtraElements(selectedLangs as string[], languages),
              selectedLangs
            ) as string[]
          );
        }
        if (
          genderFilterValue &&
          removeFilterElements(getExtraElements([genderFilterValue], genders), [
            genderFilterValue,
          ]).length !== genderFilterValue.length
        ) {
          setGenderFilterValue(
            removeFilterElements(
              getExtraElements([genderFilterValue], genders),
              [genderFilterValue]
            )[0] as string
          );
        }

        const responseLangList = [
          ...new Set(
            languages.map((item) => ({
              value: item,
              lable: item,
            }))
          ),
        ];
        setLanguagesList(responseLangList);
      }
    };
    makeAsync();
  }, [filterUpdateCounter, pagginatuions.currentPage]);

  const handleClickOutside = (event: any) => {
    const isInsideFilterMenu =
      filterRef.current && filterRef.current.contains(event.target);
    const isInsideSelect = event.target.closest(".multi-select-menu");
    const isInsideSelect2 = event.target.closest(".MuiModal-backdrop");

    if (
      !isInsideFilterMenu &&
      !isInsideSelect &&
      !isInsideSelect2 &&
      !event.target.classList.contains(s.menuIcon)
    ) {
      setIsFilterOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (
      !specializations.selectedMainSpec ||
      !specializations.selectedMainSpec.value ||
      !specializations.selectedMainSpec.value.length
    ) {
      setSpecializations({ mainSpecData: specializations.mainSpecData });
    }
  }, [specializations.selectedMainSpec]);

  const handleScroll = () => {
    if (
      specialistListRef.current &&
      pagginatuions.hasMore &&
      specialistListRef.current.scrollTop +
        specialistListRef.current.clientHeight >=
        specialistListRef.current.scrollHeight - 200 &&
      !isLoader
    ) {
      if (filterUpdateCounter === 1) {
        setFilterUpdateCounter(2);
      }
      setPagginations((prev) => ({
        ...prev,
        currentPage: pagginatuions.currentPage + 1,
      }));
    }
  };

  useEffect(() => {
    const specialistsContainer = specialistListRef.current;
    if (specialistsContainer) {
      specialistsContainer.addEventListener("scroll", handleScroll);
      return () => {
        specialistsContainer.removeEventListener("scroll", handleScroll);
      };
    }
    return;
  }, [handleScroll]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setFilterUpdateCounter((prev) => prev + 1);
      setPagginations((prev) => ({ ...prev, currentPage: 1 }));
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [debouncedSearch]);

  const handleSearchChange = (value: string) => {
    setSearch(value);
    setDebouncedSearch(value);
  };

  return (
    <div className={s.container}>
      <div className={s.filterBlock} ref={headFilterBlockRef}>
        {specializations && specializations.mainSpecData && (
          <div className={s.inputFilter}>
            <MultipleSelect
              data={
                specializations.mainSpecData
                  ? [
                      ...specializations.mainSpecData,
                      {
                        value: "initial",
                        lable: strings.filterAllSpecializations,
                      },
                    ]
                  : []
              }
              setValue={(value) => {
                {
                  setSelectedTags(undefined);
                  setPagginations((prev) => ({ ...prev, currentPage: 1 }));
                  setFilterUpdateCounter((prev) => prev + 1);
                  setSpecializations((prev) => {
                    return {
                      ...prev,
                      selectedMainSpec:
                        value === "initial"
                          ? undefined
                          : {
                              value: value,
                              label: specializations.mainSpecData.find(
                                (item) => item.value === value
                              )?.lable!,
                            },
                      subSpecData: [],
                    };
                  });
                }
              }}
              value={
                specializations.selectedMainSpec?.value
                  ? specializations.selectedMainSpec?.value
                  : "initial"
              }
              multiplie={false}
              padding="10px"
            />
          </div>
        )}

        <div className={s.inputFilter}>
          <MultipleSelect
            data={
              tagsData
                ? [
                    ...tagsData,
                    {
                      value: "initial",
                      lable: strings.filterAllDirections,
                    },
                  ]
                : []
            }
            setValue={(value) => {
              setPagginations((prev) => ({ ...prev, currentPage: 1 }));
              setFilterUpdateCounter((prev) => prev + 1);
              setSelectedTags(
                (value.length === 1 && value.includes("initial")) ||
                  (selectedTags && value?.includes("initial"))
                  ? undefined
                  : ((value as string[]).filter(
                      (el) => el !== "initial"
                    ) as string[])
              );
            }}
            value={selectedTags ?? ["initial"]}
            multiplie={true}
            padding="10px"
            checkBox
            disabled={
              tagsData &&
              tagsData.length &&
              specializations.selectedMainSpec &&
              specializations.selectedMainSpec.value.length
                ? false
                : true
            }
          />
        </div>

        <div className={s.inputFilter}>
          <MultipleSelect
            data={[
              ...levelsData,
              { value: "initial", lable: strings.filterAllLevels },
            ]}
            setValue={(value) => {
              setPagginations((prev) => ({ ...prev, currentPage: 1 }));
              setFilterUpdateCounter((prev) => prev + 1);
              setSelectedLevel(
                value === "initial" ? undefined : (value as SpecialistLevels)
              );
            }}
            value={selectedLevel ?? "initial"}
            multiplie={false}
            padding="10px"
          />
        </div>

        {/*  <div className={s.inputFilter}>
          <MultipleSelect
            data={[
              { value: "initial", lable: strings.defaultSort },
              ...sortData,
            ]}
            setValue={(value) => {
              setPagginations((prev) => ({ ...prev, currentPage: 1 }));
              setFilterUpdateCounter((prev) => prev + 1);
              setSort(value === "initial" ? undefined : (value as string));
            }}
            value={sort ?? "initial"}
            multiplie={false}
            lable={strings.sortLable}
            padding="10px"
          />
        </div> */}
        <div className={s.realTimeSwitchBlock}>
          <MuiSwitch
            lable={strings.filterRealTimeSession}
            lablePlacement="start"
            checked={
              realTimeSessionsChecked !== null ? realTimeSessionsChecked : false
            }
            setChecked={(value) => {
              setRealTimeSessionsChecked(value);
              setPagginations((prev) => ({ ...prev, currentPage: 1 }));
              setFilterUpdateCounter((prev) => prev + 1);
            }}
            disabled={isLoader}
          />
        </div>
        <div className={s.realTimeSwitchBlock}>
          <MuiSwitch
            lable={strings.filterFavourite}
            lablePlacement="start"
            checked={isFavourite === undefined ? false : isFavourite}
            setChecked={(value) => {
              setIsFavourite(value);
              setPagginations((prev) => ({ ...prev, currentPage: 1 }));
              setFilterUpdateCounter((prev) => prev + 1);
            }}
            disabled={isLoader}
          />
        </div>
        <button
          className={s.filterButton}
          onClick={!isFilterOpen ? () => setIsFilterOpen(true) : undefined}
          disabled={isFilterOpen}
        >
          <img src={FilterIcon} alt="" className={s.filterIcon} />
          <span>{strings.filtersBtn}</span>
        </button>
      </div>
      {isLoader && pagginatuions.currentPage === 1 ? (
        <div className={s.loaderBlock}>
          <ThemeProvider theme={theme}>
            <CircularProgress size={150} color="primary" />
          </ThemeProvider>
        </div>
      ) : (
        <div className={s.specialistsListBlock} ref={specialistListRef}>
          {specialistData && specialistData.length ? (
            specialistData.map((item) => (
              <div key={item._id} className={s.specialistCardContainer}>
                <SpecialistCard
                  fullName={
                    getTranslation(item.name, userData?.selectedLanguage) +
                    " " +
                    getTranslation(item.surname, userData?.selectedLanguage)
                  }
                  languages={
                    item.preferredLanguages ? item.preferredLanguages : []
                  }
                  location={item.location ? item.location : ""}
                  mainSpeciality={item.specialistIds!.map(
                    (item) =>
                      item.mainSpecializations[0].labels.find(
                        (item) => item.language === userData?.selectedLanguage
                      )?.text +
                      " " +
                      (item.level
                        ? item.level.title
                            .find(
                              (el) => el.language === userData?.selectedLanguage
                            )
                            ?.text.toLowerCase()
                        : "")
                  )}
                  userId={item._id}
                  briefDescription={
                    item.briefDescription &&
                    item.briefDescription[0] &&
                    (item.briefDescription?.find(
                      (el) => el.language === userData?.selectedLanguage
                    )?.text ??
                      item.briefDescription[0].text)
                  }
                  recommendedSpecData={item.specialistIds}
                />
              </div>
            ))
          ) : (
            <div className={s.noSpecialistBlock}>
              <NoSpecIcon />
              <h1>{strings.noSpecFound}</h1>
            </div>
          )}
          {specialistData &&
            findDifferenceToMakeMultiple(
              specialistData.length,
              window.innerWidth > 1600 ? 4 : 3
            ) > 0 &&
            Array.from(
              {
                length: findDifferenceToMakeMultiple(
                  specialistData.length,
                  window.innerWidth > 1600 ? 4 : 3
                ),
              },
              (_, index) => index
            ).map((_, index) => (
              <div key={index} className={s.emptySpecBlock}></div>
            ))}
        </div>
      )}
      <div
        className={`${s.allFiltersBlock} ${isFilterOpen ? s.open : ""}`}
        ref={filterRef}
        /* style={{
          height: 
            specialistListRef.current?.offsetHeight! +
            headFilterBlockRef.current?.offsetHeight! +
            20 +
            "px", 
        }} */
      >
        <div className={s.filtersHeader}>
          <div className={s.closeFiltersBlock}>
            <ArrowBack
              className={s.arrowBack}
              onClick={() => setIsFilterOpen(false)}
            />
            <img src={FilterIcon} alt="" className={s.filterIcon} />
            <span>{strings.filtersBtn}</span>
          </div>
          <div className={s.resetFiltersBtn} onClick={clearFilters}>
            {strings.resetFiltersBtn}
          </div>
        </div>

        <div className={s.filterOptions}>
          <div className={s.searchInputBlock}>
            <Input
              inputValue={search}
              isVisible
              required
              onChangeInput={handleSearchChange}
              placeholder={strings.filterSearchPlaceHolder}
            />
            <MagnifierIcon className={s.magnifierIcon} />
          </div>
          {/*  <div className={s.inputFilterMenu}>
            <MultipleSelect
              data={[
                { value: "initial", lable: strings.defaultSort },
                ...sortData,
              ]}
              setValue={(value) => {
                setPagginations((prev) => ({ ...prev, currentPage: 1 }));
                setFilterUpdateCounter((prev) => prev + 1);
                setSort(value === "initial" ? undefined : (value as string));
              }}
              value={sort ?? "initial"}
              multiplie={false}
              lable={strings.sortLable}
              padding="10px"
            />
          </div> */}
          {specializations && specializations.mainSpecData && (
            <div className={s.inputFilterMenu}>
              <MultipleSelect
                data={
                  specializations.mainSpecData
                    ? [
                        ...specializations.mainSpecData,
                        {
                          value: "initial",
                          lable: strings.filterAllSpecializations,
                        },
                      ]
                    : []
                }
                setValue={(value) => {
                  {
                    setSelectedTags(undefined);
                    setPagginations((prev) => ({ ...prev, currentPage: 1 }));
                    setFilterUpdateCounter((prev) => prev + 1);
                    setSpecializations((prev) => {
                      return {
                        ...prev,
                        selectedSubSpec:
                          value === "initial"
                            ? undefined
                            : prev.selectedSubSpec,
                        selectedMainSpec:
                          value === "initial"
                            ? undefined
                            : {
                                value: value,
                                label: specializations.mainSpecData.find(
                                  (item) => item.value === value
                                )?.lable!,
                              },
                        subSpecData: [],
                      };
                    });
                  }
                }}
                value={
                  specializations.selectedMainSpec?.value
                    ? specializations.selectedMainSpec?.value
                    : "initial"
                }
                multiplie={false}
                padding="10px"
              />
            </div>
          )}
          <div className={s.inputFilterMenu}>
            <MultipleSelect
              data={
                tagsData
                  ? [
                      ...tagsData,
                      {
                        value: "initial",
                        lable: strings.filterAllDirections,
                      },
                    ]
                  : []
              }
              setValue={(value) => {
                setPagginations((prev) => ({ ...prev, currentPage: 1 }));
                setFilterUpdateCounter((prev) => prev + 1);
                setSelectedTags(
                  (value.length === 1 && value.includes("initial")) ||
                    (selectedTags && value?.includes("initial"))
                    ? undefined
                    : ((value as string[]).filter(
                        (el) => el !== "initial"
                      ) as string[])
                );
              }}
              value={selectedTags ?? ["initial"]}
              multiplie={true}
              padding="10px"
              checkBox
              disabled={
                tagsData &&
                tagsData.length &&
                specializations.selectedMainSpec &&
                specializations.selectedMainSpec.value.length
                  ? false
                  : true
              }
            />
          </div>
          <div className={s.inputFilterMenu}>
            <MultipleSelect
              data={
                specializations.subSpecData
                  ? [
                      ...specializations.subSpecData,
                      {
                        value: "initial",
                        lable: strings.filterAllSpecializations,
                      },
                    ]
                  : []
              }
              setValue={(value) => {
                setPagginations((prev) => ({ ...prev, currentPage: 1 }));
                setFilterUpdateCounter((prev) => prev + 1);
                setSpecializations((prev) => {
                  return {
                    ...prev,
                    selectedSubSpec:
                      value === "initial"
                        ? undefined
                        : {
                            value: value,
                            label: specializations.mainSpecData.find(
                              (item) => item.value === value
                            )?.lable!,
                          },
                    subSpecData: [],
                  };
                });
              }}
              value={
                specializations.selectedSubSpec?.value
                  ? specializations.selectedSubSpec?.value
                  : ["initial"]
              }
              multiplie={false}
              disabled={
                specializations.selectedMainSpec &&
                specializations.selectedMainSpec.value &&
                specializations.selectedMainSpec.value.length
                  ? false
                  : true
              }
              padding="10px"
            />
          </div>
          <div className={s.inputFilterMenu}>
            <MultipleSelect
              data={[
                ...levelsData,
                { value: "initial", lable: strings.filterAllLevels },
              ]}
              setValue={(value) => {
                setPagginations((prev) => ({ ...prev, currentPage: 1 }));
                setFilterUpdateCounter((prev) => prev + 1);
                setSelectedLevel(
                  value === "initial" ? undefined : (value as SpecialistLevels)
                );
              }}
              value={selectedLevel ?? "initial"}
              multiplie={false}
              padding="10px"
            />
          </div>
          <div className={s.inputFilterMenu}>
            <MultipleSelect
              data={timeFiltersData}
              setValue={(value) => {
                setPagginations((prev) => ({ ...prev, currentPage: 1 }));
                setFilterUpdateCounter((prev) => prev + 1);
                setTimeFilterValue(
                  value === "all" ? undefined : (value as string)
                );
              }}
              value={timeFilterValue ?? "all"}
              multiplie={false}
              padding="10px"
            />{" "}
          </div>
          <div className={s.inputFilterMenu}>
            <MultipleSelect
              data={[
                ...languagesList,
                { value: "initial", lable: strings.filterAllLangs },
              ]}
              setValue={(value) => {
                setPagginations((prev) => ({ ...prev, currentPage: 1 }));
                setFilterUpdateCounter((prev) => prev + 1);

                setSelectedLangs(
                  (value.length === 1 && value.includes("initial")) ||
                    (selectedLangs && value?.includes("initial")) ||
                    !value.length
                    ? undefined
                    : ((value as string[]).filter(
                        (el) => el !== "initial"
                      ) as string[])
                );
              }}
              value={selectedLangs ?? ["initial"]}
              multiplie={true}
              checkBox
              padding="10px"
            />
          </div>
          <div className={s.inputFilterMenu}>
            <MultipleSelect
              data={[
                ...ageRangesData,
                { value: "initial", lable: strings.filterAllAge },
              ]}
              setValue={(value) => {
                setPagginations((prev) => ({ ...prev, currentPage: 1 }));
                setFilterUpdateCounter((prev) => prev + 1);
                setAgeRange(
                  value === "initial" ? undefined : (value as string)
                );
              }}
              value={ageRange ?? "initial"}
              multiplie={false}
              padding="10px"
            />
          </div>
          <div className={s.inputFilterMenu}>
            <MultipleSelect
              data={
                availableGenders
                  ? [
                      ...availableGenders,
                      { value: "initial", lable: strings.filterAllGender },
                    ]
                  : [
                      ...genderSelectData,
                      { value: "initial", lable: strings.filterAllGender },
                    ]
              }
              setValue={(value: SetStateAction<string | string[]>) => {
                setPagginations((prev) => ({ ...prev, currentPage: 1 }));
                setFilterUpdateCounter((prev) => prev + 1);
                setGenderFilterValue(
                  value === "initial" ? undefined : (value as string)
                );
              }}
              value={genderFilterValue ?? "initial"}
              multiplie={false}
              padding="10px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialistFindPage;
