import Stripe from "stripe";
import { network } from "../config";
import { CreatePaymentPayload, PaymentFor } from "../types/Balance";
import { PayoutInfo } from "../types/Payout";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import customKy from "./kyExtend";

const { stripe } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "stripe/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class StripeApi {
  static async createPayment(
    token: string,
    payload: CreatePaymentPayload
  ): Promise<
    TypeDefaultResponse & {
      checkout?: Stripe.Response<Stripe.Checkout.Session>;
    }
  > {
    return await customKy
      .post(`${baseURL}${stripe.createPayment}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async createPaymentForSession(
    token: string,
    payload: {
      order?: { promocode?: string };
      cardTokenId?: string;
      paymentFor: PaymentFor;
    }
  ): Promise<
    TypeDefaultResponse & {
      checkout?: Stripe.Response<Stripe.Checkout.Session>;
    }
  > {
    return await customKy
      .post(`${baseURL}${stripe.createPaymentForSession}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async createPaymentForSubscription(
    token: string,
    payload: {
      subscriptionId: string;
      daysAmount: number;
      redirectUrl?: string;
    }
  ): Promise<
    TypeDefaultResponse & {
      checkout?: Stripe.Response<Stripe.Checkout.Session>;
    }
  > {
    return await customKy
      .post(`${baseURL}${stripe.createPaymentForSubscription}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async savePayoutInfo(
    token: string,
    newPayoutInfo: Omit<PayoutInfo, "user" | "_id">
  ): Promise<
    TypeDefaultResponse & {
      payoutInfo?: PayoutInfo;
    }
  > {
    return await customKy
      .post(`${baseURL}${stripe.savePayoutInfo}`, {
        headers: getAuthHeader(token),
        json: { newPayoutInfo },
      })
      .then((res) => res.json());
  }

  static async getPayoutInfo(token: string): Promise<
    TypeDefaultResponse & {
      payoutInfo?: PayoutInfo;
    }
  > {
    return await customKy
      .get(`${baseURL}${stripe.getPayoutInfo}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
}
