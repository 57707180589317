import { Dayjs } from "dayjs";

export const validateTime = (
  from: Dayjs | undefined,
  to: Dayjs | undefined
): boolean => {
  if (!from || !to) return true;
  if (!from.isValid() || !to.isValid()) return false;
  const diffMinutes = to.diff(from, "minute");
  return diffMinutes >= 60 && !from.isAfter(to); // Проверка на минимальный интервал и порядок
};
