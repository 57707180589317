import { Tooltip } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import SessionsApi from "../../api/sessionApi";
import SpecialistApi from "../../api/specialistApi";
import UsersApi from "../../api/usersApi";
import { ReactComponent as FavoriteIcon } from "../../assets/Profile/specialist/favourite.svg";
import { ReactComponent as AlredyWorlIcon } from "../../assets/Profile/specialist/work-before.svg";
import { ReactComponent as ToProfileIcon } from "../../assets/SpecialistCard/navigate-to-profile.svg";
import { ISO639LangReversed } from "../../constans/languagesList";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import PackageInfo from "../../pages/PackageInfo/PackageInfo";
import { SessionPackage } from "../../types/TypeSession";
import { SpecialistData } from "../../types/TypeSpecialist";
import { createChat } from "../../utils/general";
import Notify, { SuccesNotify } from "../../utils/toaster";
import ModalWindow from "../ModalWindow/ModalWindow";
import NavigateToProfile from "../NavigateToProfile/NavigateToProfile";
import RealTimeSessionModalContent from "../RealTimeSessionModalContent/RealTimeSessionModalContent";
import SelectSessionDate from "../SelectSessionDate/SelectSessionDate";
import StandartButton from "../StandartButton/StandartButton";
import s from "./SpecialistCard.module.css";

type SpecialistCardProps = {
  userId: string;
  fullName: string;
  mainSpeciality: string[];
  languages: string[];
  location: string;
  isRecommendationCard?: boolean;
  avatarHeight?: string;
  avatarBlockBorderRadius?: string;
  mainInfoBorderRadius?: string;
  projectId?: string;
  briefDescription?: string;
  receiveRealTimeOffers?: boolean;
  specialistId?: string;
  sessionPackage?: SessionPackage;
  recommendedSpecData?: SpecialistData[];
  showActionButtons?: boolean;
  isFromTreeQuiz?: boolean;
};

const SpecialistCard = ({
  userId,
  fullName,
  mainSpeciality,
  languages,
  location,
  isRecommendationCard,
  avatarHeight,
  avatarBlockBorderRadius,
  mainInfoBorderRadius,
  projectId,
  briefDescription,
  receiveRealTimeOffers,
  specialistId,
  sessionPackage,
  recommendedSpecData,
  isFromTreeQuiz,
  showActionButtons = false,
}: SpecialistCardProps) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { userData } = useContext(ContextProvider);
  const [avatar, setAvatar] = useState("");
  const [totalInfo, setTotalInfo] = useState<{
    reviewTotal?: number;
    sessionsTotal?: number;
  }>({});
  const [isFavouriteSpec, setIsFavouriteSpec] = useState<boolean | undefined>();
  const [alreadyWork, setAlreadyWork] = useState(false);
  const [overlapTime, setOverlapTime] = useState<
    { start: Date; end: Date }[] | undefined
  >();
  const [bookingModalIsOpen, setBookingModalIsOpen] = useState(false);
  const [realTimeError, setRealTimeError] = useState("");
  const [realTimeSessionModal, setRealTimeSessionModal] = useState(false);
  const [previewPackageModal, setPreviewPackageModal] = useState(false);
  const [allPriceData, setAllPriceData] = useState<{
    [id: string]: {
      price: number;
      discountPrice: number;
      language: string;
    }[];
  }>();

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !userId) return;
      try {
        const [checkWorkResponse, favouriteResponse] = await Promise.all([
          UsersApi.checkIfUserWorkedWithSpecialist(token, userId),
          UsersApi.checkIfUserIsFavourite(token, userId),
        ]);
        if (checkWorkResponse.status) {
          setAlreadyWork(checkWorkResponse.worked);
        }
        if (favouriteResponse.status) {
          setIsFavouriteSpec(favouriteResponse.isFavourite);
        }
      } catch (error) {
        console.error(error);
      }
    };
    makeAsync();
  }, [userId, token]);

  const getSpecialistsSessionsAmount = async () => {
    if (!token || !userId) return;
    const response = await SessionsApi.getSpecialistsSessionsAmount(userId);
    if (response.status) {
      setTotalInfo((prev) => ({ ...prev, sessionsTotal: response.amount }));
    }
  };
  const getSpecialistsComments = async () => {
    if (!token || !userId) return;
    const commentsResponse = await SessionsApi.getSpecialistsComments(
      userId,
      1,
      1
    );

    setTotalInfo((prev) => ({
      ...prev,
      reviewTotal: commentsResponse.totalAmount,
    }));
  };

  useEffect(() => {
    const makeAsync = async () => {
      if (token && userId) {
        const responseAvatar = await UsersApi.getUserPublicAvatarAndName(
          userId
        );
        getSpecialistsComments();
        getSpecialistsSessionsAmount();
        if (responseAvatar.avatar) setAvatar(responseAvatar.avatar);
      }
    };
    makeAsync();
  }, [userId]);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !specialistId || !userId || !recommendedSpecData) return;

      const requests = languages.map((language) =>
        SpecialistApi.getSpecialistFreeTime(
          token,
          userId,
          specialistId,
          ISO639LangReversed[language],
          365,
          projectId,
          isFromTreeQuiz
        )
      );
      const responses = await Promise.all(requests);

      if (responses.every((el) => el.status)) {
        const priceData = responses.reduce((acc, item) => {
          const dataToAppend = {
            price: recommendedSpecData.find(
              (el) => el._id === item.specialistId
            )!.price!,
            discountPrice: item.discountPrice,
            language: item.language,
          };

          acc[item.specialistId]
            ? acc[item.specialistId].push(dataToAppend)
            : (acc[item.specialistId] = [dataToAppend]);
          return acc;
        }, {} as { [id: string]: { price: number; discountPrice: number; language: string }[] });

        setAllPriceData(priceData);

        const overlaps = responses[0].overlapDays
          .flatMap((overlap) =>
            overlap.overlaps.flatMap((dayOverlap) => dayOverlap.overlapTime)
          )
          .map((item) => ({
            start: new Date(item.start),
            end: new Date(item.end),
          }));
        setOverlapTime(overlaps);
      }
    };
    makeAsync();
  }, [specialistId, userId]);

  const bookingHandler = async (
    sessionBookingData: { date: Date | string; duration: number }[],
    specialistId: string,
    sessionLanguage: string,
    tags?: string[],
    subject?: string
  ) => {
    if (
      token &&
      userData &&
      userData._id &&
      sessionBookingData.length &&
      specialistId
    ) {
      const response = await SessionsApi.bookSession(
        {
          datesAndTime: sessionBookingData,
          specialistUserId: userId,
          userId: userData._id,
          specialistId: specialistId,
          projectId: projectId,
          tags: tags,
          subject: subject,
          sessionLanguage: sessionLanguage,
        },
        token
      );

      if (!response.status && !response.notEnoughBalance) {
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
      if (!response.status && response.notEnoughBalance && response.message) {
        Notify(response.message);
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
      if (response.status) {
        createChat(userData, userId);
        setBookingModalIsOpen(false);
        SuccesNotify(strings.bookingSucces);
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
    }
    return { status: false, notEnoughBalance: false };
  };

  const sendRealTimeSessionRequest = async (
    sessionSubject: string,
    sessionTags: string[],
    setSubjectValue: Dispatch<SetStateAction<string>>,
    specialistId: string,
    sessionLanguage: string
  ) => {
    if (!token || !userData) return { status: false };
    const payload = {
      specialistUserId: userId,
      specialistId: specialistId,
      userId: userData?._id,
      subject: sessionSubject,
      projectId: projectId,
      tags: sessionTags,
      sessionLanguage: sessionLanguage,
    };
    const response = await SpecialistApi.sendRealTimeSessionRequest(
      token,
      payload
    );

    if (!response.status && !response.notEnoughBalance) {
      setRealTimeError(response.message!);
      return {
        status: response.status,
        notEnoughBalance: response.notEnoughBalance,
      };
    }
    if (!response.status && response.notEnoughBalance && response.message) {
      return {
        status: response.status,
        notEnoughBalance: response.notEnoughBalance,
      };
    }

    if (response.status) {
      navigate("/global-chat", {
        state: {
          userId: userId,
        },
      });
      setSubjectValue("");
      setRealTimeError("");
      setRealTimeSessionModal(false);
      return {
        status: response.status,
        notEnoughBalance: response.notEnoughBalance,
      };
    }
    return {
      status: response.status,
    };
  };

  return (
    <div
      className={window.innerWidth > 600 ? s.container : s.mobileContainer}
      style={{ flexDirection: isRecommendationCard ? "row" : "column" }}
    >
      <NavigateToProfile
        userId={userId}
        width={isRecommendationCard ? "fit-content" : "100%"}
        height="100%"
        state={{ projectId: projectId, isFromTreeQuizParams: isFromTreeQuiz }}
      >
        <div className={s.avatarBlock}>
          {!avatar || avatar === "" ? (
            <div
              className={s.noAvatarBlock}
              style={{
                width: isRecommendationCard ? "200px" : "100%",
                height: isRecommendationCard ? "250px" : "auto",
                borderRadius: avatarBlockBorderRadius ?? "20px 20px 0 0",
              }}
            >
              {fullName.split(" ")[0][0]}
              {fullName.split(" ")[1][0]}
            </div>
          ) : (
            <img
              src={avatar}
              alt=""
              className={s.avatarBlur}
              height={avatarHeight ?? "auto"}
              width={isRecommendationCard ? "200px" : "100%"}
              style={{
                borderRadius: avatarBlockBorderRadius ?? "20px 20px 0 0",
              }}
            />
          )}
          <div
            className={s.blurActionBlock}
            style={{
              borderRadius: isRecommendationCard
                ? "0 0 0 20px"
                : "20px 20px 0 0",
            }}
          >
            <div className={s.buttonsBlock}>
              <div className={s.navigateToProfileBtn}>
                <ToProfileIcon className={s.profileIcon} />
                <span className={s.viewProfileText}>
                  {strings.viewProfileBtn}
                </span>
              </div>
            </div>
          </div>
        </div>
      </NavigateToProfile>
      <div
        className={
          window.innerWidth > 600 ? s.mainInfoBlock : s.mobileMainInfoBlock
        }
        style={{
          borderRadius: mainInfoBorderRadius ?? "0 0 20px 20px",
          height: isRecommendationCard ? "auto" : "inherit",
        }}
      >
        <div className={s.fullNameValue}>{fullName}</div>
        <div className={s.specializationValue}>{mainSpeciality.join(", ")}</div>
        {totalInfo.reviewTotal !== undefined &&
          totalInfo.sessionsTotal !== undefined && (
            <div className={s.totalBlock}>
              <div className={s.totalElement}>
                <span>{totalInfo.reviewTotal}</span>
                <span>{strings.reviewsSpecCArd}</span>
              </div>
              <div className={s.totalElement}>
                <span>{totalInfo.sessionsTotal}</span>
                <span>{strings.sessionSpecCard}</span>
              </div>
            </div>
          )}
        <div className={s.briefDescription}>{briefDescription}</div>
        <div style={{ marginBlockStart: "auto" }}>
          <div className={s.locationRatingBlock}>
            <span className={s.locationValue}>{location}</span>
            {/*  <span className={s.ratingValue}>
              {strings.ratingSpecProfile + " " + rating?.toFixed(1)}
            </span> */}
          </div>
          <div className={s.professionalInfoBlock}>
            <div>
              {languages.map((item, index) => (
                <span className={s.langValue} key={index}>
                  {item + " "}
                </span>
              ))}
            </div>
            <div className={s.iconsBlock}>
              {isFavouriteSpec && (
                <Tooltip arrow title={strings.inFavoiteTooltip}>
                  <FavoriteIcon className={s.favIcon} />
                </Tooltip>
              )}
              {alreadyWork && (
                <Tooltip arrow title={strings.alreadyWorked}>
                  <AlredyWorlIcon className={s.alreadyWorkIcon} />
                </Tooltip>
              )}
            </div>
          </div>
          {showActionButtons &&
            overlapTime &&
            (sessionPackage ? (
              <div className={s.actionBlock}>
                <StandartButton
                  action={() => setPreviewPackageModal(true)}
                  buttonTitle={strings.showPackageBtn}
                  width="100%"
                />
              </div>
            ) : (
              <div className={s.actionBlock}>
                <StandartButton
                  action={() => setBookingModalIsOpen(true)}
                  buttonTitle={strings.specCardBookingBtn}
                  width="100%"
                />
                {receiveRealTimeOffers && (
                  <StandartButton
                    action={() => setRealTimeSessionModal(true)}
                    buttonTitle={strings.specCardStratNowBtn}
                    width="100%"
                  />
                )}
              </div>
            ))}
        </div>
      </div>
      {recommendedSpecData && allPriceData && (
        <ModalWindow
          isOpen={realTimeSessionModal}
          setIsOpen={setRealTimeSessionModal}
          width="fit-content"
        >
          <RealTimeSessionModalContent
            allSpecializations={recommendedSpecData.map((item) => ({
              specialistId: item._id,
              specialization: item.mainSpecializations[0].specialization,
              lable:
                item.mainSpecializations![0].labels.find(
                  (el) => el.language === userData?.selectedLanguage
                )?.text ?? item.mainSpecializations[0].specialization,
              tags: item.tags,
            }))}
            allSessionLangs={languages.map((lang) => ({
              //@ts-expect-error
              lable: strings[lang],
              value: ISO639LangReversed[lang],
            }))}
            priceData={allPriceData}
            initialSpecialization={recommendedSpecData[0]._id}
            fullName={fullName}
            realTimeError={realTimeError}
            sendRequestHandler={sendRealTimeSessionRequest}
            specialistUserId={userId}
          />
        </ModalWindow>
      )}
      {recommendedSpecData && overlapTime && specialistId && allPriceData && (
        <ModalWindow
          isOpen={bookingModalIsOpen}
          setIsOpen={setBookingModalIsOpen}
          width="fit-content"
          bgColor={overlapTime ? "White" : "transparent"}
          showCloseBtn={false}
        >
          <SelectSessionDate
            allSpecializations={recommendedSpecData.map((item) => ({
              specialistId: item._id,
              bookingTimeStep: item.bookingTimeStep,
              specialization: item.mainSpecializations[0].specialization,
              lable:
                item.mainSpecializations![0].labels.find(
                  (el) => el.language === userData?.selectedLanguage
                )?.text ?? item.mainSpecializations[0].specialization,
              tags: item.tags,
              introductionSession: item.introductionSession,
            }))}
            allSessionLangs={languages.map((lang) => ({
              //@ts-expect-error
              lable: strings[lang],
              value: ISO639LangReversed[lang],
            }))}
            priceData={allPriceData}
            setModalOpen={setBookingModalIsOpen}
            bookingHandler={bookingHandler}
            overlapTime={{ [specialistId]: overlapTime }}
            userId={userId}
            fullname={fullName}
          />
        </ModalWindow>
      )}
      {sessionPackage && (
        <ModalWindow
          isOpen={previewPackageModal}
          setIsOpen={setPreviewPackageModal}
          bgColor="var(--gray-bg-color)"
          width="90vw"
        >
          <PackageInfo
            propsPackageId={sessionPackage?._id}
            discountPrice={sessionPackage.packageCost / 100}
            projectId={projectId}
          />
        </ModalWindow>
      )}
    </div>
  );
};
export default SpecialistCard;
