import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import MuiSwitch from "../../../../components/MuiSwitch/MuiSwitch";
import strings from "../../../../localization";
import s from "./Services.module.css";

interface ServiceConfigBase {
  title: string;
  description: string;
  value: boolean;
  allowed: boolean;
  price?: {
    language: string;
    clientCost: number;
    specialistIncome: number;
    selected: boolean;
  }[];
}

type ServiceConfig = ServiceConfigBase;

type ServicesData = Record<string, ServiceConfig>;

type ConfigElementProps = {
  config: ServiceConfigBase;
  objKey: string;
  setServicesData: Dispatch<SetStateAction<ServicesData>>;
  updateServiceConfig: (key: keyof ServicesData, value: boolean) => void;
};
const ConfigElement = ({
  config,
  objKey,
  updateServiceConfig,
}: ConfigElementProps) => {
  const [localValue, setLocalValue] = useState(config.value);

  const handleSwitchChange = async (value: boolean) => {
    setLocalValue(value); // Локально обновляем значение для моментальной реакции
    await updateServiceConfig(objKey, value); // Сохраняем изменения через API
  };

  useEffect(() => {
    setLocalValue(config.value); // Синхронизируем локальное состояние при изменении `config.value`
  }, [config.value]);

  return (
    <div className={s.configElement}>
      <div className={s.serviceConfigMainBlock}>
        <div className={s.configTextBlock}>
          <span className={s.configTitle}>{config.title}</span>
          <span className={s.configDescription}>{config.description}</span>
        </div>
        <CustomTooltip title={strings.noAllowed} hideTooltip={config.allowed}>
          <div style={{ opacity: config.allowed ? 1 : 0.5 }}>
            <MuiSwitch
              checked={localValue}
              setChecked={handleSwitchChange}
              lable=""
              disabled={!config.allowed}
            />
          </div>
        </CustomTooltip>
      </div>
    </div>
  );
};

export default React.memo(ConfigElement);
