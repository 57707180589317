import { Dispatch, SetStateAction } from "react";
import DialogChatAPI from "../api/dialogChatApi";
import UsersApi from "../api/usersApi";
import strings from "../localization";
import { SessionsData } from "../pages/Initial/Initial";
import { TypeUserData } from "../types/TypeUsers";

interface FormattedDateTime {
  formattedDate: string;
  formattedTime: string;
}

const token = localStorage.getItem("token");
const sessionEndGap = process.env.REACT_APP_SESSION_END_GAP;

export const areUserDataEqual = (
  userData1: TypeUserData | null | undefined,
  userData2: TypeUserData | null | undefined
): boolean => {
  return JSON.stringify(userData1) === JSON.stringify(userData2);
};

export const changeLanguage = async (lang: string) => {
  strings.setLanguage(lang);
};

export const logout = async (userId: string) => {
  await UsersApi.logout({ userId });
  localStorage.clear();
  window.location.href = "/choice-mode";
};
export const formateDate = (date: Date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  const formattedDate = `${day}.${month}.${year}`;
  return formattedDate;
};

export const getUserAvatars = async (
  userIds: string[],
  setAvatars?: Dispatch<SetStateAction<{ [userId: string]: string }>>
) => {
  if (userIds.length) {
    const promises = userIds.map((userId) =>
      UsersApi.getUserPublicAvatarAndName(userId)
    );

    Promise.all(promises)
      .then((results) => {
        const avatarsData = results.reduce((acc, response) => {
          if (response.avatar) {
            acc[response._id] = response.avatar;
          }
          return acc;
        }, {} as { [userId: string]: string });
        setAvatars && setAvatars(avatarsData);
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export const checkTimeRemaining = (
  sessionStartDate: Date,
  timeZone: string
): boolean => {
  const currentDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: timeZone })
  );
  const timeDiff = sessionStartDate.getTime() - currentDate.getTime();
  const minutesRemaining = Math.floor(timeDiff / (1000 * 60));

  return minutesRemaining <= 10;
};
export const filterRecentSessions = (
  sessions: SessionsData[],
  timeZone: string
): SessionsData[] => {
  const currentTime = new Date(
    new Date().toLocaleString("en-US", { timeZone: timeZone })
  );
  const currentYear = currentTime.getFullYear();

  const recentSessions = sessions.filter((session) => {
    const [day, month] = session.sessionDate.split(".");
    const sessionStart = new Date(
      `${currentYear}-${month}-${day}T${session.sessionTime}`
    );
    const timeLimit = new Date(
      currentTime.getTime() - Number(sessionEndGap) * 60 * 1000
    );

    return sessionStart > timeLimit;
  });
  recentSessions.sort((a, b) => {
    const [dayA, monthA] = a.sessionDate.split(".");
    const [dayB, monthB] = b.sessionDate.split(".");
    const dateA = new Date(`${currentYear}-${monthA}-${dayA}T${a.sessionTime}`);
    const dateB = new Date(`${currentYear}-${monthB}-${dayB}T${b.sessionTime}`);

    return dateA.getTime() - dateB.getTime();
  });

  return recentSessions;
};

export const checkElapsedTime = (
  sessionStartDate: Date,
  timeZone: string
): boolean => {
  const currentDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: timeZone })
  );

  const timeDiff = currentDate.getTime() - sessionStartDate.getTime();
  const minutesElapsed = Math.floor(timeDiff / (1000 * 60));
  return minutesElapsed < Number(sessionEndGap);
};
export const findDifferenceToMakeMultiple = (
  number: number,
  multiple: number
): number => {
  let remainder = number % multiple;
  let difference = multiple - remainder;
  if (remainder === 0) {
    return 0;
  } else {
    return difference;
  }
};
export const formatDateTimeWithOutTimezone = (
  dateTimeString: Date
): FormattedDateTime => {
  const [datePart, timePart] = dateTimeString.toString().split("T");
  const [yearStr, monthStr, dayStr] = datePart.split("-");
  const [hoursStr, minutesStr] = timePart.split(":");

  // Создаем объект Date с указанными значениями
  const year = parseInt(yearStr);
  const month = parseInt(monthStr) - 1; // Месяцы в объекте Date начинаются с 0
  const day = parseInt(dayStr);
  const hours = parseInt(hoursStr);
  const minutes = parseInt(minutesStr);

  // Создаем объект Date с указанными значениями
  const dateObject = new Date(year, month, day, hours, minutes);

  const dayOfMonth = dateObject.getDate().toString().padStart(2, "0");
  const monthOfYear = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const formattedDate = `${dayOfMonth}.${monthOfYear}`;

  const hoursOfDay = dateObject.getHours().toString().padStart(2, "0");
  const minutesOfHour = dateObject.getMinutes().toString().padStart(2, "0");
  const formattedTime = `${hoursOfDay}:${minutesOfHour}`;

  return { formattedDate, formattedTime }; // { formattedDate: '22.08', formattedTime: '08:00' }
};

export const createChat = async (userData: TypeUserData, userId: string) => {
  if (token && userData && userData._id) {
    const payload = {
      userId: userData._id,
      userIdToSend: userId,
    };
    await DialogChatAPI.createDialogChat(token, payload);
  }
};
export const storeData = async (value: string, varTitle: string) => {
  try {
    localStorage.setItem(varTitle, value);
  } catch (e) {
    console.log(e);
  }
};
